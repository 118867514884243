import { createSlice } from '@reduxjs/toolkit'

export const viewPointLevels = createSlice({
  name: 'viewPointLevels',
  initialState: {
    data: [],
    current: null
  },
  reducers: {
    setViewPointLevels: (state, action) => {
      state.data = action.payload
    },
    setCurrentViewPointLevel: (state, action) => {
      state.current = action.payload
    },
    clear: (state) => {
      state.data = [];
      state.current = null;
    },
  },
})

export const { setViewPointLevels, setCurrentViewPointLevel, clear } = viewPointLevels.actions

export default viewPointLevels.reducer