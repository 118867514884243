import React from "react";

const PageHeaderTitle = ({ title, cClass = 'd-center', classTitle = "", isHTML = false }) => {
    return (
        <div className={'header ' + cClass}>
            {
                isHTML ? <div className={"page-header-title " + classTitle} dangerouslySetInnerHTML={{__html: title}}></div>
                : <span className={"page-header-title mob-header " + classTitle}>{title}</span>
            }
        </div>
    );
};

export default PageHeaderTitle;
