import { createSlice } from '@reduxjs/toolkit'

export const services = createSlice({
  name: 'services',
  initialState: {
    data: [],
    current: null
  },
  reducers: {
    setServices: (state, action) => {
      state.data = action.payload
    },
    setCurrentService: (state, action) => {
      state.current = action.payload
    },
    clear: (state) => {
      state.data = [];
      state.current = null;
    },
  },
})

export const { setServices, setCurrentService, clear } = services.actions

export default services.reducer