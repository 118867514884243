import { createSlice } from '@reduxjs/toolkit'
import appHelper from '../helper/appHelper';

export const settings = createSlice({
  name: 'settings',
  initialState: {
    data: null,
    unavailable_model: "c63925ba83a447d580f2407e961a419a",
    min_minutes_to_confirm_pickup: null,
    cancel_reasons: [],
    arcgis_web_scene_id: "0930dac165d240fcb8ce7ecd297ca077",
    zone_url: "https://zone.air.city",
    custom_class_full_screen: '',
    distance_get_arrival_stations: 1,
    threshold_to_determine_two_images_same: 0.38,
    timeout_change_tip: null,
    open_onboarding_screen: appHelper.getValueLocalStore(appHelper.keyMetaOpenOnboarding) !== null ? false : true,
    basemap_2d_layer: "arcgis-light-gray",
  },
  reducers: {
    setData: (state, action) => {
      state.data = action.payload;
      if (action.payload && action.payload.length) {
        const settingZoneUrl = action.payload.find(item => item.key == appHelper.keyZoneLevelURLAddress);
        if (settingZoneUrl && settingZoneUrl.value) {
          state.zone_url = settingZoneUrl.value.endsWith("/") ? settingZoneUrl.value.slice(0, -1) : settingZoneUrl.value;
        }
        const settingDistanceGetArrivalStations = action.payload.find(item => item.key == appHelper.keyDistanceGetArrivalStations);
        if (settingDistanceGetArrivalStations && settingDistanceGetArrivalStations.value) {
          state.distance_get_arrival_stations = Number(settingDistanceGetArrivalStations.value / 1000);
        }
        const settingThresholdToDetermine = action.payload.find(item => item.key == appHelper.keyThresholdToDetermineTwoImagesSame);
        if (settingThresholdToDetermine && settingThresholdToDetermine.value) {
          state.threshold_to_determine_two_images_same = Number(settingThresholdToDetermine.value);
        }
      }
    },
    setUnavailableModel: (state, action) => {
      state.unavailable_model = action.payload;
    },
    setMinMinutesToConfirmPickup: (state, action) => {
      state.min_minutes_to_confirm_pickup = action.payload;
    },
    setCancelReasons: (state, action) => {
      state.cancel_reasons = action.payload;
    },
    setArcgisWebSceneID: (state, action) => {
      state.arcgis_web_scene_id = action.payload;
    },
    setZoneURL: (state, action) => {
      state.zone_url = action.payload;
    },
    setCustomClassFullScreen: (state, action) => {
      state.custom_class_full_screen = action.payload;
    },
    setDistanceGetArrivalStations: (state, action) => {
      state.distance_get_arrival_stations = action.payload;
    },
    setTimeoutChangeTip: (state, action) => {
      state.timeout_change_tip = action.payload;
    },
    setOpenOnboardingScreen: (state, action) => {
      state.open_onboarding_screen = action.payload;
    },
    setBaseMap2DLayer: (state, action) => {
      state.basemap_2d_layer = action.payload;
    },
    clear: (state) => {
      state.data = null;
      state.unavailable_model = "c63925ba83a447d580f2407e961a419a";
      state.min_minutes_to_confirm_pickup = null;
      state.cancel_reasons = [];
      state.arcgis_web_scene_id = "0930dac165d240fcb8ce7ecd297ca077";
      state.zone_url = "https://zone.air.city";
      state.custom_class_full_screen = '';
      state.distance_get_arrival_stations = 1;
      state.threshold_to_determine_two_images_same = 0.38;
      state.timeout_change_tip = null;
      state.basemap_2d_layer= "arcgis-light-gray";
    },
  },
})

export const { setData, setUnavailableModel, setMinMinutesToConfirmPickup, setCancelReasons, setArcgisWebSceneID, setZoneURL, setCustomClassFullScreen, setDistanceGetArrivalStations, setTimeoutChangeTip, setOpenOnboardingScreen, setBaseMap2DLayer, clear } = settings.actions

export default settings.reducer