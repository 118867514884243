import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import appHelper from "../../helper/appHelper";
import ArrowBack from "../../components/arrowBack";
import React, { useState, useEffect, } from "react";
import PageHeaderTitle from "../../components/PageHeaderTitle";
import "./identification-card.css";
import User from '../../assets/images/kyc_user.png'
import ModalCustom from "../../components/ModalCustom";
import accountService from "../../services/accountService";
const KycLanding = () => {
    var meta_data = appHelper.getValueLocalStore(appHelper.keyMetaConfirmBook);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [title, setTitle] = useState("KYC");
    const location = useLocation();
    const { state } = location;
    const { cProfile } = state || {};
    const [hasSkippedKyc, setHasSkippedKyc] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isVerified, setIsVerified] = useState(false);

    const userData = JSON.parse(localStorage.getItem('air_car_rental_user'));
    const user_id = null
    // const userUID = userData.user_uid;
    // const userEmail = userData.email;
    useEffect(() => {
        if (sessionStorage.getItem("hasSkippedKyc")) {
            setHasSkippedKyc(true);
        }
        getProfile()

    }, []);
    const skip = () => {
        setIsModalOpen(true);
    };
    async function getProfile() {
        await accountService.getProfile().then(async (response) => {

            if (response.data) {
                const data = response.data;
                localStorage.setItem("user_Id", response.data.uid)
                setIsVerified(response.data.email_status === 1 ? true : false)
                return response.data;
            } else {
                setTimeout(() => {
                    navigate("/login");
                }, 2500);
            }
        });
    }


    const confirmSkip = () => {
        setHasSkippedKyc(true);
        sessionStorage.setItem("hasSkippedKyc", "true");
        setIsModalOpen(false);
        navigate(appHelper.setNavigateUrlMTC());
    };

    // const skip = () => {
    //     setHasSkippedKyc(true);
    //     sessionStorage.setItem("hasSkippedKyc", "true");
    //     navigate(appHelper.setNavigateUrlMTC())
    // };
    const nav = () => {
        navigate("/verify-identification-card", { state: { cProfile: cProfile } })
    };
    function onNavigate() {
        navigate(appHelper.setNavigateUrlMTC(), { replace: true });
    }
    const onNavigation = () => {
        // {
        //     isVerified ?
        navigate("/mtc")
        //         :
        // navigate("/edit-profile")
        // }
    }
    return (
        <>
            <div className="container_custom identity-container">
                <div className="air-header-global">
                    <ArrowBack onClickBack={() => { onNavigation() }} />
                    <PageHeaderTitle title={title} />
                    <div className="position-relative">
                        <div className="d-close-onboarding">

                        </div>
                    </div>
                </div>
                <div className='box-content-global loading-box2 text-center MuiBox-root css-0' >
                    <div className='main-box' style={{ justifyContent: "center", display: "block" }}>
                        <div className="row loading-div g-0 p-3 pb-1">

                            <div className="col-12 text-center">
                                {/* <Hourglass
                                    visible={true}
                                    height="80"
                                    width="80"
                                    ariaLabel="hourglass-loading"
                                    wrapperStyle={{}}
                                    wrapperClass=""
                                    colors={['#A9D9FF', '#7eb2ff']}
                                /> */}
                                <img src={User} className="img-fluid checked-image" alt="Check" />
                            </div>
                            <div className="col-12 kyc-middle-heading mt-1">
                                Verify your Identity!
                            </div>
                            <div className="col-12 mt-1 kyc-identity text-capitalize">
                                <div>Verifying your identity helps us safeguard</div>
                                <div>your account from unauthorized access.</div>
                                <div>It ensures a smooth and</div>
                                <div>secure experience on our platform.</div>
                            </div>
                        </div>
                        <div className="horizontal-line mb-2"></div>
                        <div className="col-12">
                            <div className="document-heading">Please have these documents with you before starting</div>
                            <div className="ps-3">
                                <div className="user-heading mt-1 text-start">For Hong kong User</div>
                                <ul className="row d-flex">
                                    <li className="text-start user-subheading col-5" style={{ lineHeight: "20px" }}>Hong Kong ID</li>
                                    <li className="text-start user-subheading col-7" style={{ lineHeight: "20px" }}>Driver License</li>

                                </ul>
                                <div className="user-heading text-start">
                                    For Non Hong kong User
                                </div>
                                <ul className="row d-flex mr-2">
                                    <li className="text-start user-subheading col-5" style={{ lineHeight: "20px" }}>Passport</li>
                                    <li className="text-start user-subheading col-7" style={{ lineHeight: "20px" }}>International Driver Permit</li>

                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className='text-center '>
                        <button type="submit" className='btn-style-1' onClick={() => { nav() }} style={{ width: "90%" }}>
                            Continue
                        </button>
                    </div>
                    {!hasSkippedKyc && (
                        <div className='text-center mt-3'>
                            <button type="submit" className='btn-style-2' onClick={() => { skip() }} style={{ width: "90%" }}>
                                Skip
                            </button>
                        </div>
                    )}
                </div>

            </div>

            <ModalCustom
                open={isModalOpen}
                handleClose={() => setIsModalOpen(false)}
                hasIconClose={true}
                content={
                    <div className="row mt-3">
                        <div className="col-12 p-3 text-center">
                            <label className="label-add-to-screen" style={{ fontSize: "18px" }}>Submitting the KYC documents will only takes a few minutes.</label>
                        </div>

                        <div className="d-center p-3">
                            <button
                                type="button"
                                className="btn-style-2 m-2"
                                style={{ minWidth: '40%', background: "#aeadad" }}
                                onClick={() => { confirmSkip() }}
                            >
                                Just Skip
                            </button>
                            <button
                                type="button"
                                className="btn-style-1 m-2"
                                style={{ minWidth: '40%' }}
                                onClick={() => { nav() }}
                            >
                                Continue
                            </button>
                        </div>

                    </div>
                }
            />

        </>
    )
}

export default KycLanding
