import config from "../services/config";
import moment from "moment";
import en from 'world_countries_lists/data/countries/en/world.json';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import * as turf from '@turf/turf';

const AuthLocalStore = "air_car_rental_user";
const keyMetaOpenInPWA = "meta_open_in_pwa";
const keyMetaOpenOnboarding = "meta_open_onboarding";
const keyMetaDataAddPaymentMethod = "meta_data_add_payment_method";
const keyMetaDataVerifyDrivingLicense = "meta_data_verify_driving_license";
const keyMetaDataVerifyIdentificationCard = "meta_data_verify_identification_card";
const keyMetaDataCarDetail = "meta_data_car_detail";
const keyMetaConfirmBook = "meta_confirm_book";
const keyMetaBookSuccess = "meta_book_success";
const keyMetaReminderBeforeCheckout = "meta_reminder_before_checkout";
const keyMetaLoadFaceApiModels = "meta_meta_load_face_api_models";
const keyFaceModelsCdnUrl = "https://cdn.jsdelivr.net/gh/cgarciagl/face-api.js@0.22.2/weights/";
const keyDistanceAllowPickupAndReturnCar = "distance_allow_pickup_and_return_car"
const keyMinMinutesToConfirmPickup = "key_minimum_minutes_user_to_confirm_pickup_car"
const keyUnavailableModel = "sketchfab_3d_modle_unavailable_car"
const keyArcgisBaseMap2dLayer = "arcgis_base_map_2d_layer"
const keyDistanceAllowUserLockUnlockCar = "distance_allow_user_lock_unlock_car"
const keyPlusZ = "key_plus_z";
const keyIsPlusZ = "key_is_plus_z";
const keyConnectionNetWork = "key_connection_net_work";
const keyViewLevelID = "key_view_level_id";
const keyStationID = "key_station_id";
const keyMinimumBatteryNeedToCharged = "minimum_battery_level_need_to_charged_when_checkout";
const keyArcgisOnlineWebSceneID = "arcgis_online_web_scene_id";
const keyZoneLevelURLAddress = "zone_level_url_address";
const keyDistanceGetParkingsWithinUserLocation = "distance_get_parkings_within_radius_from_user_location";
const keyDistanceGetCarFromStation = "key_distance_get_cars_within_radius_from_parking_station_location";
const keySecurityDepositBooking = "security_deposit_booking";
const keyAuthorizationAmountHeldAfterCheckout = "authorization_amount_held_after_checkout";
const keyMaximumDaysAuthorization = "maximum_number_of_days_authorization"
const keyOperatingSecondsOfHazardLights = "operating_seconds_of_hazard_lights"
const keyDistanceGetArrivalStations = "distance_get_arrival_stations_from_suggested_location"
const keyThresholdToDetermineTwoImagesSame = "config_threshold_to_determine_two_images_are_the_same"

const optionsGoto = {
	duration: 2000,
	easing: "liner"
}

const optionsMapConstraints = {
	altitude: {
		min: 100,
		max: 250,
	}
}

const specialStationTypes = ["Closed", "Multi-floor"];

function getUserInfo(key) {
	let business = window.localStorage.getItem(AuthLocalStore);
	business = JSON.parse(business);
	if (!business) return null;
	return business[key];
}

function getImageUrl(url) {
	if (!url) return null;
	if (url.includes("http")) return url;

	return config.baseUrl + "/" + url;
}

function getAbsUrl(url) {
	if (!url) return null;
	if (url.includes("http")) return url;

	return "http://" + url;
}

const delay = (ms) => new Promise((res) => setTimeout(res, ms));

function capitalizeFirstLetter(e) {
	return typeof e != "undefined" && e ? e.charAt(0).toUpperCase() + e.slice(1) : '';
}

function formatLocationName(location) {
	let splits = location.toLowerCase().split(" ");
	return splits.map(item => capitalizeFirstLetter(item)).join(" ")
}

function formatAmountUs(number) {
	number = Number(number);
	let numDigits = number % 1 == 0 ? 0 : 2;
	return new Intl.NumberFormat('en-US', { minimumFractionDigits: numDigits, maximumFractionDigits: numDigits }).format(number)
}

function formatDistance(distance) {
	if (isEmpty(distance)) {
		return '0 Km'
	}
	let number = distance;
	return formatAmountUs(number) + ' Km'
}

function formatDistanceLocation(distance) {
	if (isEmpty(distance)) {
		return '0 m'
	}
	return (distance > 100 || distance < 1 ? parseFloat(distance < 1 ? distance * 1000 : distance).toFixed(0) : parseFloat(distance).toFixed(2)) + (distance > 1 ? ' km' : ' m')
}

function formatDuration(trip_duration_seconds) {
	const seconds = trip_duration_seconds ? Number(trip_duration_seconds) : 0;
	const minutes = Math.round(seconds / 60);
	return minutes ? minutes + (minutes > 1 ? ' minutes' : ' minute') : '';
}

function formatDurationTrip(trip_duration_seconds) {
	const seconds = trip_duration_seconds ? Number(trip_duration_seconds) : 0;
	if (seconds < 60) {
		return seconds + (seconds > 1 ? ' Secs' : ' Sec');
	}
	const minutes = Math.round(seconds / 60);
	return minutes ? minutes + (minutes > 1 ? ' Mins' : ' Min') : '';
}

function formatDurationSummary(trip_duration_seconds) {
	const duration = moment.duration(Math.abs(trip_duration_seconds), 'seconds');
	let hours = duration.hours();
	const days = duration.days();
	const minutes = duration.minutes() + (duration.seconds() ? 1 : 0);

	let result = '';
	if (days > 0) {
		hours += (days * 24)
	}

	if (hours > 0) {
		result += `${hours} ${hours > 1 ? 'Hours' : 'Hour'} `;
	}

	if (minutes > 0) {
		result += `${minutes} ${minutes > 1 ? 'Mins' : 'Min'} `;
	}
	return result;
}

function formatDurationMinutes(seconds) {
	return Math.ceil((seconds ? Number(seconds) : 0) / 60) + ' Min';
}

function formatTripStartEndTime(start, end) {
	if (moment(start).startOf("day").isSame(moment(end).startOf("day"))) {
		return <span className="time-start ms-1">{moment(start).format(config.C_DATE_FORMAT)} {moment(start).format("HH:mm:ss")}<ArrowRightAltIcon />{moment(end).format("HH:mm:ss")}</span>
	} else {
		return <span className="time-start ms-1">{moment(start).format(config.C_DATE_TIME_FORMAT)}<ArrowRightAltIcon />{moment(end).format(config.C_DATE_TIME_FORMAT)}</span>
	}
}

function formatTime(time) {
	return time ? moment(time).format("DD MMM HH:mm:ss") : ''
}

function customStationName(station, isShowFloorParking = true, last_floor = null, last_lot_number = null) {
	let subFloorParking = [];
	if (isShowFloorParking && station.carpark_station_type && specialStationTypes.includes(station.carpark_station_type)) {
		if (station.carpark_station_type == "Multi-floor" && last_floor && (station.has_floor || station.sub_type == 'fixed')) {
			subFloorParking.push('Floor no. ' + last_floor)
		}
		if (last_lot_number && (station.has_number || station.sub_type == 'fixed')) {
			subFloorParking.push('Lot no. ' + last_lot_number)
		}
	}
	return <div className="">
		<div>{station.name}</div>
		{
			subFloorParking.length ? <div className="text-address">{subFloorParking.join(", ")}</div> : ''
		}
		{
			station.location_description ? <div className="text-address">{station.location_description}</div> : ''
		}
	</div>
}

function isEmpty(value) {
	return value == undefined || value == null || value == '' || (typeof value == 'object' && Object.keys(value).length == 0) || (typeof value == 'array' && value.length == 0);
}

function isValidEmail(email) {
	return /\S+@\S+\.\S+/.test(email);
}

function getRemainingSeconds(booking) {
	const endTime = moment(booking.pick_up_deadline_at);
	const seconds = endTime.diff(moment(), "seconds");
	return seconds <= 0 ? 0 : seconds;
}

function formatCountdownTime(value) {
	let minutes = 0;
	if (value && Number(value)) {
		minutes = Math.floor(value / 60);
		if (value % 60) {
			minutes += 1;
		}
	}
	return minutes + (minutes > 1 ? " mins" : " min");
}

const userStatuses = {
	pending: 'pending',
	active: 'active',
	inactive: 'inactive'
}

const licenseStatuses = {
	unverified: "unverified",
	pending: 'pending',
	verified: 'verified',
	rejected: 'rejected'
}

const identificationStatuses = {
	unverified: "unverified",
	pending: 'pending',
	verified: 'verified',
	rejected: 'rejected'
}

const classVerifyStatus = {
	unverified: "warning",
	pending: 'info',
	verified: 'success',
	rejected: 'danger'
}

const textVerifyStatus = {
	unverified: "Unverified",
	pending: 'Verifying',
	verified: 'Verified',
	rejected: 'Rejected'
}

const bookingStatuses = {
	'pending': 'info',
	'on-going': 'primary',
	'completed': 'success',
	'expired': 'danger',
	'canceled': 'danger',
}

const carLockColorStatues = {
	'LOW': 'success',
	'HIGHT': 'warning',
}

const carLockTextStatues = {
	'LOW': 'Locked',
	'HIGHT': 'Unlocked',
}

const colorPaymentStatues = {
	'unpaid': 'warning',
	'paid': 'success',
}

const invoiceConstants = {
	STATUSES: {
		PENDING: 'pending',
		SUCCEEDED: 'succeeded',
		CANCELED: 'canceled',
		EXPIRED: 'expired',
	},
	PAYMENT_STATUSES: {
		UNPAID: 'unpaid',
		PAID: 'paid'
	},
	TYPES: {
		CAPTURE_PAYMENT: 'capture_payment',
		AUTHORIZE: 'authorize',
		PAYMENT: 'payment',
	},
}

const transactionConstants = {
	STATUSES: {
		PENDING_STATUS: 'pending',
		SUCCEEDED_STATUS: 'succeeded',
		CANCEL_STATUS: 'cancel',
		REQUIRES_CAPTURE: 'requires_capture'
	},
	TYPES: {
		INCREMENT_AUTHORIZE: 'increment_authorize',
		CAPTURE_PAYMENT: 'capture_payment',
		AUTHORIZE: 'authorize',
		PAYMENT: 'payment'
	}
}

export function getClassVerifyStatus(status) {
	return classVerifyStatus[status] ? classVerifyStatus[status] : ''
}

export function CVerifyStatus(status, cClass = 'fw-semibold') {
	return (
		<span className={`${cClass} text-${getClassVerifyStatus(status)}`}>
			{capitalizeFirstLetter(textVerifyStatus[status])}
		</span>
	)
}

function isRealPayments(invoice) {
	return [transactionConstants.TYPES.CAPTURE_PAYMENT, transactionConstants.TYPES.PAYMENT].includes(invoice.type);
}

function getRealPayments(payments) {
	return payments.filter(payment => [transactionConstants.TYPES.CAPTURE_PAYMENT, transactionConstants.TYPES.PAYMENT].includes(payment.type) && payment.status == transactionConstants.STATUSES.SUCCEEDED_STATUS);
}

function getClassBookingStatus(status) {
	return bookingStatuses[status] ? bookingStatuses[status] : ''
}

function showPlateNumber(status) {
	return !['pending', 'canceled'].includes(status)
}

function bookingPending(booking) {
	return booking.status == "pending"
}

function bookingOngoing(booking) {
	return booking.status == 'on-going'
}

function bookingCanceled(booking) {
	return booking.status == 'canceled'
}

function bookingCompleted(booking) {
	return booking.status == 'completed'
}

export function checkImage(imgUrl, imgId, img_undefined) {
	if (imgUrl && imgUrl != '') {
		if (!imgUrl.includes('http') && !imgUrl.includes("base64")) {
			const appBaseURl = process.env.REACT_APP_BASE_URL
				? process.env.REACT_APP_BASE_URL.replace('api', '')
				: null
			imgUrl = appBaseURl + imgUrl
		}
		if (imgUrl) {
			let img = new Image()
			img.onload = () => {
				let imgEle = document.getElementById(imgId)
				if (imgEle) {
					imgEle.classList.remove('d-none')
					imgEle.src = imgUrl
				}
			}
			img.onerror = () => {
				let imgEle = document.getElementById(imgId)
				if (imgEle) {
					imgEle.src = img_undefined
				}
			}
			img.src = imgUrl
			return img.src
		}
	} else {
		return img_undefined
	}
}

function showLabelDuration(value, type) {
	return value + ' ' + type + (Number(value) > 1 ? 's' : '');
}

function getValueLocalStore(key) {
	let value = localStorage.getItem(key);
	value = value ? JSON.parse(value) : null;
	return value;
}

function setValueLocalStore(key, value) {
	localStorage.setItem(key, JSON.stringify(value));
}

function clearValueLocalStore(key) {
	localStorage.removeItem(key);
}

function checkFakeEmail(email) {
	if (email && email.includes("fake_email")) {
		return '';
	}
	return email;
}

function findCountry(query) {
	if (undefined === query.id && undefined === query.alpha2 && undefined === query.alpha3) return null;
	return en.filter(function (country) {
		return (
			// we are searching by ID and we have a match
			(undefined !== query.id && parseInt(country.id, 10) === parseInt(query.id, 10))
			// or we are searching by alpha2 and we have a match
			|| (undefined !== query.alpha2 && country.alpha2 === query.alpha2.toLowerCase())
			// or we are searching by alpha3 and we have a match
			|| (undefined !== query.alpha3 && country.alpha3 === query.alpha3.toLowerCase())
		)
	}).pop()
}

function profileNeedICVerify(profile) {
	return profile && (profile.identification_card_status !== identificationStatuses.verified || (profile.identification_card_expired_date && moment(profile.identification_card_expired_date) <= moment()));
}

function profileNeedDLVerify(profile) {
	return profile && (profile.onfido_document_status !== "approved" || (profile.license_expired_date && moment(profile.license_expired_date) <= moment()));
}

function calculateDistance(lat1, long1, lat2, long2) {
	const R = 6371; // Earth radius in kilometers
	const dLat = toRadians(lat2 - lat1);
	const dLon = toRadians(long2 - long1);

	const a =
		Math.sin(dLat / 2) * Math.sin(dLat / 2) +
		Math.cos(toRadians(lat1)) * Math.cos(toRadians(lat2)) *
		Math.sin(dLon / 2) * Math.sin(dLon / 2);

	const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
	const distance = R * c; // Distance in kilometers

	return distance;
}

function toRadians(degrees) {
	return degrees * (Math.PI / 180);
}

function findNearestLocation(currentLat, currentLon, stations, hasAvailableCars = false) {
	stations = stations.map(station => {
		const distance = calculateDistance(currentLat, currentLon, station.lat, station.long);
		return { ...station, distance: distance };
	}).sort((station1, station2) => {
		return station1.distance - station2.distance;
	});
	if (hasAvailableCars) {
		stations = stations.sort((station1, station2) => {
			return station1.available_cars || (station2.available_cars - station1.available_cars);
		});
	}
	return stations.length ? stations[0] : null;
}

function renderCancelMessage(settings) {
	let penaltyAmount = 50;
	let minutes = 10;
	let minutesConfirmPickup = 30;
	if (settings && settings.length) {
		let settingMinutesCancelNotPenalty = settings.find(item => item.key == "minimum_minutes_of_cancel_not_incur_penalty");
		let settingPenaltyAmount = settings.find(item => item.key == "penalty_amount_cancel_booking");
		let settingMinutesConfirmPickup = settings.find(item => item.key == "key_minimum_minutes_user_to_confirm_pickup_car");
		penaltyAmount = settingPenaltyAmount && (settingPenaltyAmount.value || settingPenaltyAmount.value == 0) ? Number(settingPenaltyAmount.value) : penaltyAmount;
		minutes = settingMinutesCancelNotPenalty && (settingMinutesCancelNotPenalty.value || settingMinutesCancelNotPenalty.value == 0) ? Number(settingMinutesCancelNotPenalty.value) : minutes
		minutesConfirmPickup = settingMinutesConfirmPickup && (settingMinutesConfirmPickup.value || settingMinutesConfirmPickup.value == 0) ? Number(settingMinutesConfirmPickup.value) : minutesConfirmPickup
	}
	return <div>
		<div className="cancel-message">
			1. Can be cancelled within <span className="fw-bold">{minutes}</span> minutes, otherwise a <span className="fw-bold">HK${penaltyAmount}</span> charge will be applied.
		</div>
		<div className="cancel-message">
			2. Vehicle not picked up within <span className="fw-bold">{minutesConfirmPickup}</span> minutes will be charged the total fare.
		</div>
	</div>
}

function getRectangleCorners(latitude, longitude, radiusInMeters = 3) {
	const circle = turf.circle([longitude, latitude], radiusInMeters);
	const bbox = turf.bbox(circle);
	// console.log("Square: " + config.URL_GOOGLE_MAP_DIR + "/" + bbox[1] + "," + bbox[0] + "/" + bbox[1] + "," + bbox[2] + "/" + bbox[3] + "," + bbox[2] + "/" + bbox[3] + "," + bbox[0])
	return bbox;
}

function getPlusZ(newPositionZ) {
	let isPlusZ = getValueLocalStore(keyIsPlusZ);
	isPlusZ = typeof isPlusZ != "undefined" ? Number(isPlusZ) : 1;
	let plusZ = getValueLocalStore(keyPlusZ);
	plusZ = !plusZ || (plusZ && Number(plusZ) < 0) ? 0 : plusZ;
	if (isPlusZ) {
		plusZ = 1
		newPositionZ = newPositionZ + 0.2
	} else {
		plusZ = 0
		newPositionZ = newPositionZ - 0.2
	}
	setValueLocalStore(keyPlusZ, plusZ);
	setValueLocalStore(keyIsPlusZ, isPlusZ);
	return newPositionZ;
}

function isSafari() {
	return navigator.vendor && navigator.vendor.indexOf('Apple') > -1 &&
		navigator.userAgent &&
		navigator.userAgent.indexOf('CriOS') == -1 &&
		navigator.userAgent.indexOf('FxiOS') == -1;
}

function isIOS() {
	const iOS_1to12 = /iPad|iPhone|iPod/.test(navigator.platform);
	const iOS13_iPad = (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1);

	const iOS1to12quirk = function () {
		var audio = new Audio(); // temporary Audio object
		audio.volume = 0.5; // has no effect on iOS <= 12
		return audio.volume === 1;
	};

	return !window.MSStream && (iOS_1to12 || iOS13_iPad || iOS1to12quirk());
}

function isMobile() {
	return /Mobi|Android|iPhone|iPad|iPod/i.test(navigator.userAgent);
}

function isOpenOnGoogle() {
	return navigator.userAgent && navigator.userAgent.includes("Chrome");
}

function isOpenPWA() {
	return window.matchMedia('(display-mode: fullscreen)').matches || window.matchMedia('(display-mode: standalone)').matches
}

function openGGMap(url) {
	const win = window.open(url, '_top');
	return win.focus();
}

function createShader(gl, src, type) {
	const shader = gl.createShader(type);
	gl.shaderSource(shader, src);
	gl.compileShader(shader);
	return shader;
}

function createProgram(gl, vsSource, fsSource) {
	const program = gl.createProgram();
	if (!program) {
		console.error("Failed to create program");
	}
	const vertexShader = createShader(gl, vsSource, gl.VERTEX_SHADER);
	const fragmentShader = createShader(gl, fsSource, gl.FRAGMENT_SHADER);
	gl.attachShader(program, vertexShader);
	gl.attachShader(program, fragmentShader);
	gl.linkProgram(program);
	const success = gl.getProgramParameter(program, gl.LINK_STATUS);
	if (!success) {
		console.error(`Failed to link program:
	 error ${gl.getError()},
	 info log: ${gl.getProgramInfoLog(program)},
	 vertex: ${gl.getShaderParameter(vertexShader, gl.COMPILE_STATUS)},
	 fragment: ${gl.getShaderParameter(fragmentShader, gl.COMPILE_STATUS)}
	 vertex info log: ${gl.getShaderInfoLog(vertexShader)},
	 fragment info log: ${gl.getShaderInfoLog(fragmentShader)}`);
	}
	return program;
}

// Vertex shader program
const vshader = `#version 300 es

  in vec2 position;
  out vec2 uv;

  void main() {
	  gl_Position = vec4(position, 0.0, 1.0);
	  uv = position * 0.5 + vec2(0.5);
  }`;

// Fragment shader program
const fshader = `#version 300 es

  precision highp float;
  out lowp vec4 fragColor;
  in vec2 uv;

  uniform sampler2D colorTex;
  uniform sampler2D depthTex;

  uniform float focus;
  uniform float aperture;
  uniform vec2 nearFar;

  const float maxBlur = 0.015;

  // sample points that we use for image blurring
  const vec2 samplePoints[41] = vec2[41](
	  vec2(0.0, 0.0),
	  vec2(0.0,   0.4),
	  vec2(0.15,  0.37),
	  vec2(0.29,  0.29),
	  vec2(-0.37,  0.15),
	  vec2(0.40,  0.0),
	  vec2(0.37, -0.15),
	  vec2(0.29, -0.29),
	  vec2(-0.15, -0.37),
	  vec2(0.0,  -0.4),
	  vec2(-0.15,  0.37),
	  vec2(-0.29,  0.29),
	  vec2(0.37,  0.15),
	  vec2(-0.4,   0.0),
	  vec2(-0.37, -0.15),
	  vec2(-0.29, -0.29),
	  vec2(0.15, -0.37),
	  vec2(0.135, 0.333),
	  vec2(-0.333, 0.135),
	  vec2(0.333, -0.135),
	  vec2(-0.135, -0.333),
	  vec2(-0.135,  0.333),
	  vec2(0.333,  0.135),
	  vec2(-0.333, -0.135),
	  vec2(0.135, -0.333),
	  vec2(0.2,  0.2),
	  vec2( 0.28,  0.0),
	  vec2( 0.2, -0.2),
	  vec2( 0.0,  -0.28),
	  vec2(-0.2,  0.2),
	  vec2(-0.28,   0.0),
	  vec2(-0.2, -0.2),
	  vec2( 0.0,   0.28),
	  vec2(0.11,  0.11),
	  vec2( 0.16,  0.0),
	  vec2( 0.11, -0.11),
	  vec2( 0.0,  -0.16),
	  vec2(-0.11,  0.11),
	  vec2(-0.16,   0.0),
	  vec2(-0.11, -0.11),
	  vec2( 0.0,   0.16)
  );

  // noise function used to smooth the edges of the blur
  vec2 noise() {
	float x = (fract(sin(dot(gl_FragCoord.xy ,vec2(12.9898,78.233) * 0.143)) * 43758.5453) - 0.5) / 2.0;
	float y = (fract(sin(dot(gl_FragCoord.xy ,vec2(12.9898,78.233) * 0.219)) * 43758.5453) - 0.5) / 2.0;
	return vec2(x, y) * 0.4;
  }

  // convert depth value [0, 1] to [near, far] values
  float linearizeDepth(float depth) {
	float depthNdc = depth * 2.0 - 1.0;
	return (2.0 * nearFar[0] * nearFar[1]) / (depthNdc * (nearFar[1] - nearFar[0]) - (nearFar[1] + nearFar[0]));
  }

  // read depth texture and calculate depth value
  float linearDepth(vec2 uv) {
	ivec2 iuv = ivec2(uv * vec2(textureSize(depthTex, 0)));
	float depth = texelFetch(depthTex, iuv, 0).r;
	return linearizeDepth(depth);
  }

  void main() {
	vec2 size = vec2(textureSize(colorTex, 0));
	vec2 aspectCorrection = vec2(1.0, size.x / size.y);

	vec4 color = vec4(0.0);
	float viewZ = linearDepth(uv);
	float factor = (focus + viewZ); // viewZ is <= 0, so this is a difference equation

	vec2 noise = noise();
	vec2 blur = vec2(clamp(factor * aperture, -maxBlur, maxBlur));
	for (int i = 0; i < 41; i++) {
	  color += pow(texture(colorTex, uv.xy + ((samplePoints[i] + noise) * aspectCorrection) * blur), vec4(2.2));
	};
	fragColor = pow(color / 41.0, vec4(1./2.2));
	fragColor.a = 1.0;
  }`;

const compassHeading = (alpha, beta, gamma) => {
	// Convert degrees to radians
	const alphaRad = alpha * (Math.PI / 180);
	const betaRad = beta * (Math.PI / 180);
	const gammaRad = gamma * (Math.PI / 180);

	// Calculate equation components
	const cA = Math.cos(alphaRad);
	const sA = Math.sin(alphaRad);
	const cB = Math.cos(betaRad);
	const sB = Math.sin(betaRad);
	const cG = Math.cos(gammaRad);
	const sG = Math.sin(gammaRad);

	// Calculate A, B, C rotation components
	const rA = - cA * sG - sA * sB * cG;
	const rB = - sA * sG + cA * sB * cG;
	const rC = - cB * cG;

	// Calculate compass heading
	let compassHeading = Math.atan(rA / rB);

	// Convert from half unit circle to whole unit circle
	if (rB < 0) {
		compassHeading += Math.PI;
	} else if (rA < 0) {
		compassHeading += 2 * Math.PI;
	}

	// Convert radians to degrees
	compassHeading *= 180 / Math.PI;
	return compassHeading;
};

function locationHandler(latitude, longitude) {
	let pointDegree = calcDegreeToPoint(latitude, longitude);
	if (pointDegree < 0) {
		pointDegree = pointDegree + 360;
	}
	return Number(parseFloat(pointDegree).toFixed(2));
}

function calcDegreeToPoint(latitude, longitude) {
	// Qibla geolocation
	const point = {
		lat: 21.422487,
		lng: 39.826206
	};

	const phiK = (point.lat * Math.PI) / 180.0;
	const lambdaK = (point.lng * Math.PI) / 180.0;
	const phi = (latitude * Math.PI) / 180.0;
	const lambda = (longitude * Math.PI) / 180.0;
	const psi =
		(180.0 / Math.PI) *
		Math.atan2(
			Math.sin(lambdaK - lambda),
			Math.cos(phi) * Math.tan(phiK) -
			Math.sin(phi) * Math.cos(lambdaK - lambda)
		);
	return Math.round(psi);
}

function getEnvironmentLighting() {
	return {
		type: "sun",
		date: new Date(),
		defaultDate: new Date(),
		directShadowsEnabled: true,
		displayUTCOffset: 0
	}
}

function getEstimatePrice(route) {
	try {
		return Number(route.basic_fare) + (Number(route.est_travelling_time) * Number(route.basic_fare_permint))
	} catch (error) {
		console.log(error);
		return 0
	}
}

function getEstimateTime(route) {
	try {
		return route.est_travelling_time && Number(route.est_travelling_time) ? Number(route.est_travelling_time) + (route.buffer && Number(route.buffer) ? Number(route.buffer) : 0) : 0;
	} catch (error) {
		console.log(error);
		return 0
	}
}

function customStations(stations) {
	return stations.map((item) => {
		return {
			latitude: item.lat,
			longitude: item.long,
			value: item.id,
			label: customStationName(item, false),
			...item,
		};
	});
}

function setNavigateUrlMTC() {
	let redirectUrl = '/';
	let metaStationId = getValueLocalStore(keyStationID)
	let metaViewPointLevelId = getValueLocalStore(keyViewLevelID)
	if (metaStationId) {
		redirectUrl += metaStationId
	} else if (metaViewPointLevelId && Number(metaViewPointLevelId)) {
		redirectUrl += '?view_point_level_id=' + metaViewPointLevelId;
	}
	return redirectUrl;
}

function getNavigateKYCUrl(cProfile) {
	var navigateUrl = null;
	if (!cProfile.payment_methods || cProfile.payment_methods.length == 0) {
		navigateUrl = "/add-payment-method";
	} else if (!cProfile.email || !checkFakeEmail(cProfile.email)) {
		navigateUrl = "/edit-profile";
	} else if (profileNeedDLVerify(cProfile)) {
		navigateUrl = "/kyc";
	}
	return navigateUrl;
}

const borderOutLine = {
	border: '1px solid #000000',
	outline: 'none',
	boxShadow: 'none',
}

const inputFocus = {
	boxShadow: '5px 5px 10px 0px rgba(170, 170, 204, 0.5) inset',
	border: 'none',
	outline: 'none',
}

const tabBgBoxShadow = {
	...inputFocus,
	backgroundColor: '#F5F5FA'
}

const tabBgBoxShadowHover = {
	...inputFocus,
	backgroundColor: '#F5F5FA',
	border: '1px solid #000000',
}

const customRulesFormAddPaymentMethod = {
	'.Tab': tabBgBoxShadow,
	'.Tab--selected': tabBgBoxShadowHover,
	'.Tab:hover': tabBgBoxShadowHover,
	'.Tab:focus': tabBgBoxShadowHover,
	'.Tab:active': tabBgBoxShadowHover,
	'.TabIcon--selected': {
		fill: '#000000',
	},
	'.TabLabel--selected': {
		color: '#000000'
	},
	'.Input': {
		fontSize: '14px',
		padding: '8px 16px 6px 16px',
		borderRadius: '4px',
		backgroundColor: "rgb(245, 245, 250)",
		border: 'none',
		boxShadow: '5px 5px 10px 0px rgba(170, 170, 204, 0.5) inset'
	},
	'.Input:hover': inputFocus,
	'.Input:focus': inputFocus,
	'.Input:active': inputFocus,
	'.p-Input--hovered': inputFocus,
	'.p-Input--focused': inputFocus,
	'.Input:active': inputFocus,
	'.Input--invalid': inputFocus,
	'.Input:disabled, .Input--invalid:disabled': {
		color: 'lightgray'
	},
	'.p-FieldLabel': {
		fontSize: '13px',
	},
	'.Error': {
		fontSize: '12px',
		marginTop: '6px'
	},
	'.p-GridCell': {
		marginTop: '5px'
	}
};

export default {
	AuthLocalStore,
	getUserInfo,
	getImageUrl,
	delay,
	getAbsUrl,
	capitalizeFirstLetter,
	formatLocationName,
	formatAmountUs,
	isEmpty,
	isValidEmail,
	getClassBookingStatus,
	getRealPayments,
	checkImage,
	formatDuration,
	formatDurationTrip,
	formatDistance,
	formatDistanceLocation,
	CVerifyStatus,
	getValueLocalStore,
	setValueLocalStore,
	clearValueLocalStore,
	showLabelDuration,
	getRemainingSeconds,
	formatCountdownTime,
	formatDurationSummary,
	showPlateNumber,
	bookingPending,
	bookingOngoing,
	bookingCanceled,
	bookingCompleted,
	checkFakeEmail,
	isRealPayments,
	findCountry,
	profileNeedICVerify,
	profileNeedDLVerify,
	calculateDistance,
	findNearestLocation,
	formatTripStartEndTime,
	formatTime,
	renderCancelMessage,
	getRectangleCorners,
	formatDurationMinutes,
	getPlusZ,
	createProgram,
	compassHeading,
	isSafari,
	isIOS,
	isMobile,
	isOpenOnGoogle,
	isOpenPWA,
	openGGMap,
	getEnvironmentLighting,
	locationHandler,
	customStationName,
	getEstimatePrice,
	getEstimateTime,
	customStations,
	getNavigateKYCUrl,
	setNavigateUrlMTC,
	specialStationTypes,
	vshader,
	fshader,
	colorPaymentStatues,
	invoiceConstants,
	bookingStatuses,
	carLockColorStatues,
	carLockTextStatues,
	transactionConstants,
	userStatuses,
	licenseStatuses,
	identificationStatuses,
	keyMetaOpenOnboarding,
	keyMetaConfirmBook,
	keyMetaBookSuccess,
	keyMetaDataAddPaymentMethod,
	keyMetaDataVerifyDrivingLicense,
	keyMetaDataVerifyIdentificationCard,
	keyMetaDataCarDetail,
	keyMetaLoadFaceApiModels,
	keyFaceModelsCdnUrl,
	keyMetaReminderBeforeCheckout,
	keyDistanceAllowPickupAndReturnCar,
	keyUnavailableModel,
	keyArcgisBaseMap2dLayer,
	keyMinMinutesToConfirmPickup,
	keyDistanceAllowUserLockUnlockCar,
	keyPlusZ,
	keyIsPlusZ,
	keyConnectionNetWork,
	keyViewLevelID,
	keyStationID,
	keyArcgisOnlineWebSceneID,
	keyZoneLevelURLAddress,
	keyDistanceGetParkingsWithinUserLocation,
	keyDistanceGetCarFromStation,
	keyMinimumBatteryNeedToCharged,
	keySecurityDepositBooking,
	keyAuthorizationAmountHeldAfterCheckout,
	keyMaximumDaysAuthorization,
	keyMetaOpenInPWA,
	keyOperatingSecondsOfHazardLights,
	keyDistanceGetArrivalStations,
	keyThresholdToDetermineTwoImagesSame,
	optionsGoto,
	optionsMapConstraints,
	customRulesFormAddPaymentMethod
};
