import { useLocalStorage } from "./useLocalStorage";
import { useNavigate } from "react-router-dom";
import auth from "../services/auth";
import React, { createContext, useContext, useMemo } from "react";
import appHelper from "../helper/appHelper";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
	const navigate = useNavigate();
	const [user, setUser] = useLocalStorage(appHelper.AuthLocalStore, null);
	let user_data = localStorage.getItem("air_car_rental_user");
	const token = user_data?.token

	const _setUser = async (response) => {
		if (user_data) {
			auth.setAuthorizationToken(user_data.token);
			setUser(user_data);

		}
	};

	const login = async (response) => {
		if (response) {
			setUser(response);
			auth.setAuthorizationToken(response?.token);
		}
	};

	const logout = () => {
		setUser(null);
		navigate("/", { replace: true });
	};

	const value = useMemo(
		() => ({
			user,
			login,
			logout,
			_setUser,
		}),
		[user]
	);

	return (
		<AuthContext.Provider value={value}>{children}</AuthContext.Provider>
	);
};

export const useAuth = () => {
	return useContext(AuthContext);
};
