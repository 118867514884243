import React from "react";
import { Box, IconButton } from "@mui/material";
import arrowBack from "../assets/images/arrow_back.svg"

const ArrowBack = ({ boxClass, onClickBack }) => {
    return (
        <Box className={boxClass ?? "arrow-back"}>
            <IconButton
                onClick={() => { onClickBack() }}
                aria-label="back"
                className="btn-back d-center mob-btn"
            >
                <img src={arrowBack} alt="Back" />
            </IconButton>
        </Box>
    );
};

export default ArrowBack;
