import { createSlice } from '@reduxjs/toolkit'

export const obj3DModel = createSlice({
  name: 'obj3DModel',
  initialState: {
    data: null,
  },
  reducers: {
    setObj3DModel: (state, action) => {
      state.data = action.payload;
    },
    clear: (state) => {
      state.data = null;
    },
  },
})

export const { setObj3DModel, clear } = obj3DModel.actions

export default obj3DModel.reducer