export default {
	screen: { overflow: 'auto', background: "#F5F5FA" },
	pinkColor: "#FBA3FF",
	grayColor: "#AFB1B6",
	orangeColor: "#F86F19",
	tabTitle: {
		color: "#7A8096",
		fontWeight: "600",
		fontSize: "14px",
		textTransform: "none",
		"&.Mui-selected": {
			color: "white",
		},
		"&.MuiTabs-indicator": {
			display: "none",
			//backgroundColor: "orange"
		},
	},
	ast: {
		position: "absolute",
		top: 0,
		left: 0,
	},
	asb: {
		position: "fixed",
		bottom: 0,
		maxWidth: '430px',
		zIndex: 10,
		margin: "0 auto",
		width: "100%",
	},
	defaultBtn: {
		background: "linear-gradient(91.07deg, #A942F5 3.02%, #4B12F6 95.67%)",
		borderRadius: "24px",
		height: "50px",
		width: "100%",
	},
	getBackgroundImageAttr: function (image) {
		return {
			backgroundSize: "cover",
			backgroundPosition: "center",
			backgroundRepeat: "no-repeat",
			backgroundImage: `url(${image})`,
		};
	},
	layoutOutside: {
		backgroundColor: "white",
		height: "auto",
		position: "relative",
		// pt: "25px",
	},
	layoutCenter: {
		display: "flex",
		alignContent: "center",
		alignItems: "center",
		px: "50px",
		position: "relative",
	},
	backgroundGradient:
		"linear-gradient(91.07deg, #A942F5 3.02%, #4B12F6 95.67%)",
	panelCenter: {
		justifyContent: "center",
		alignItems: "center",
		display: "flex",
		flexDirection: "column",
	},
	modalStyle: {
		position: "absolute",
		top: "50%",
		left: "50%",
		width: "320px",
		transform: "translate(-50%, -50%)",
		borderRadius: "10px",
		background: "white",
		border: 0,
	},
	divider: {
		mt: "22px",
		mb: "22px",
		background: "#333333",
		height: "1px",
		width: "100%",
	},
	inputProps: {
		input: {
			color: "#7A8096",
			fontSize: "15px",
			fontWeight: 600,
			height: "48px",
		},
		"& .MuiInput-underline:before": {
			borderBottomColor: "#7A8096",
		},
		"& .MuiInput-underline:after": {
			borderBottomColor: "#7A8096",
		},
		"& .MuiInput-underline:hover": {
			borderBottomColor: "#7A8096",
		},
	},
};
