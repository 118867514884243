import { lazy } from "react";
import OTPForm from "../components/OTPForm";
import DocStatus from "../views/verify-profile/DocStatus.js";
import KycFinal from "../views/verify-profile/KycFinal.js";
import KycLanding from "../views/verify-profile/KycLanding.js";
import VerifyEmail from "../views/profile/edit/VerifyEmail.js";
import VerifyAddress from "../views/profile/edit/VerifyAddress.js";

const MainLayout = lazy(() => import("../layouts/Main"));
const Login = lazy(() => import("../views/auth/login"));
const AvailableMTC = lazy(() => import("../views/mtc/available"));
const UnavailableMTC = lazy(() => import("../views/mtc/unavailable"));
const BookingDetail = lazy(() => import("../views/booking/detail"));
const Health = lazy(() => import("../views/health"));
const Version = lazy(() => import("../views/version"));
const VerifyIC = lazy(() => import("../views/verify-profile/identification-card"));
const VerifyDL = lazy(() => import("../views/verify-profile/driving-license"));
const Page404 = lazy(() => import("../views/page-404"));
const Ratings = lazy(() => import("../views/booking/Ratings"));
const ETA = lazy(() => import("../views/mtc/etaTimer"));
const UpdateProfile = lazy(() => import("../views/profile/edit/index.js"));
const FormAddPaymentMethod = lazy(() => import("../views/add-payment-method/form"));
const VerifyFaces = lazy(() => import("../views/booking/VerifyToUnlock.js"));

function getRoutes() {
	const appRoutes = [
		{
			path: "/login",
			element: <Login />,
		},
		{
			path: "/OTP",
			element: <OTPForm />,
		},
		{
			path: "/health",
			element: <Health />,
		},
		{
			path: "/version",
			element: <Version />,
		},

		{
			path: "/",
			element: <MainLayout />,
			children: [
				{
					path: "/verification-status",
					element: <DocStatus />,
					authentication: true,
					isShowAddHomeScreen: false
				},
				{
					path: "/activity/:booking_id",
					element: <BookingDetail />,
					authentication: true,
					isShowAddHomeScreen: true
				},
				{
					path: "/:booking_id/rating",
					element: <Ratings />,
					authentication: true,
					isShowAddHomeScreen: true
				},
				{
					path: "/verify-identification-card",
					element: <VerifyIC />,
					authentication: true,
					hidden_menu: true
				},
				{
					path: "/verify-driving-license",
					element: <VerifyDL />,
					authentication: true,
					hidden_menu: true
				},
				{
					path: "/edit-profile",
					element: <UpdateProfile />,
					authentication: true,
					hidden_menu: true
				},
				{
					path: "/kyc",
					element: <KycLanding />,
					authentication: true,
					hidden_menu: true
				},
				{
					path: "/kyc-status",
					element: <KycFinal />,
					authentication: true,
					hidden_menu: true
				},
				{
					path: "/add-payment-method",
					element: <FormAddPaymentMethod />,
					authentication: true,
					hidden_menu: true
				},
				{
					path: "/verify-to-unlock",
					element: <VerifyFaces />,
					authentication: true,
					hidden_menu: true
				},
				{
					path: "/",
					element: <AvailableMTC />,
					authentication: false
				},
				{
					path: "/:station_id",
					element: <AvailableMTC />,
					authentication: true
				},
				// {
				// 	path: "/:station_id",
				// 	element: <UnavailableMTC />,
				// 	authentication: false
				// },
				{
					path: "/eta",
					element: <ETA />,
					authentication: true,
					hidden_menu: true
				},
				{
					path: "/verify-email",
					element: <VerifyEmail />,
					authentication: true,
					hidden_menu: true
				},
				{
					path: '/verify-address',
					element: <VerifyAddress />,
					authentication: true,
					hidden_menu: true
				}

			]
		},
		{
			path: "/*",
			element: <Page404 />,
			authentication: false,
			hidden_menu: true
		},

	];

	return appRoutes;
}

export default {
	getRoutes: getRoutes,
};