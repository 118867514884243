import { createSlice } from '@reduxjs/toolkit'

export const notification = createSlice({
  name: 'notification',
  initialState: {
    data: {},
  },
  reducers: {
    setDataNotify: (state, action) => {
      state.data = action.payload;
      state.data.open = true;
    },
    clear: (state) => {
      state.data = {};
    },
  },
})

export const { setDataNotify, clear } = notification.actions

export default notification.reducer