import { createSlice } from '@reduxjs/toolkit'

export const bookings = createSlice({
  name: 'bookings',
  initialState: {
    data: [],
    ongoing_booking: null,
    user_force_close: false,
    is_open_suggest_ongoing: false,
    unlock_car: true,
    force_open_modal_annotation: false,
    current_booking: null,
  },
  reducers: {
    storeOrUpdateBooking: (state, action) => {
      let data = JSON.parse(JSON.stringify(state.data));
      let index = data.findIndex(item => item.id == action.payload.id);
      if (index > -1) {
        data[index] = action.payload
      } else {
        data.push(action.payload)
      }
      state.data = data;
    },
    setOngoingBooking: (state, action) => {
      state.ongoing_booking = action.payload;
    },
    setCurrentBooking: (state, action) => {
      state.current_booking = action.payload;
    },
    setUserForceClose: (state, action) => {
      state.user_force_close = action.payload;
    },
    setIsOpenSuggestOngoing: (state, action) => {
      state.is_open_suggest_ongoing = action.payload;
    },
    clearDataBookingByID: (state, action) => {
      let data = JSON.parse(JSON.stringify(state.data));
      data = data.filter(item => item.id != action.payload)
      state.data = data;
    },
    setUnlockCar: (state, action) => {
      state.unlock_car = action.payload;
    },
    setForceOpenModalAnnotation: (state, action) => {
      state.force_open_modal_annotation = action.payload;
    },
    clear: (state) => {
      state.data = [];
      state.ongoing_booking = null;
      state.current_booking = null;
      state.user_force_close = false;
      state.is_open_suggest_ongoing = false;
      state.unlock_car = false;
      state.force_open_modal_annotation = false;
    },
  },
})

export const { storeOrUpdateBooking, clearDataBookingByID, setOngoingBooking, setCurrentBooking, setUserForceClose, setIsOpenSuggestOngoing, setUnlockCar, setForceOpenModalAnnotation, clear } = bookings.actions

export default bookings.reducer