import appTheme from "../../consts/appTheme";

const typography = {
	fontFamily: appTheme.fontFamily,
	h1: {
		fontWeight: 400,
		fontSize: 42,
		lineHeight: "50px",
	},
	h2: {
		fontWeight: 400,
		fontSize: 28,
		color: "white",
	},
	h2b: {
		fontWeight: 700,
		fontSize: 21,
		color: "white",
	},
	p12: {
		fontWeight: 400,
		fontSize: 12,
	},
	p12b: {
		fontWeight: 600,
		fontSize: 12,
	},
	
	p14: {
		fontWeight: 400,
		fontSize: 14,
		lineHeight: "20px",
	},

	p14r: {
		fontWeight: 400,
		fontSize: 14,
		lineHeight: "20px",
	},
	p14m: {
		fontWeight: 500,
		fontSize: 14,
		lineHeight: "24px",
	},
	p14b: {
		fontWeight: 600,
		fontSize: 14,
		lineHeight: "24px",
	},
	p16b: {
		fontWeight: 600,
		fontSize: 16,
	},
	p18: {
		fontWeight: 400,
		fontSize: 18,
	},
	p18b: {
		fontWeight: 600,
		fontSize: 18,
	},
	buttons: {
		textTransform: "none",
	},

	p12r: {
		fontFamily: "Tomorrow",
		fontSize: 14,
	},
};

export default typography;
