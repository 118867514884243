import { createSlice } from '@reduxjs/toolkit'

export const carHistoryTab = createSlice({
  name: 'carHistoryTab',
  initialState: {
    isNearest: false,
    isMostBooked: false,
    carId: null
  },
  reducers: {
    setIsNearest: (state, action) => {
      state.isNearest = action.payload
    },
    setIsMostBooked: (state, action) => {
      state.isMostBooked = action.payload
    },
    setCarId: (state, action) => {
      state.carId = action.payload
    },
    clear: (state) => {
      state.isNearest = false;
      state.isMostBooked = false;
      state.carId = null;
    },
  },
})

export const { setIsNearest, setIsMostBooked, setCarId, clear } = carHistoryTab.actions

export default carHistoryTab.reducer