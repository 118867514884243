import { Box, Button } from "@mui/material";
import { setData } from "../../../store/settings";
import { setDataNotify } from '../../../store/notification'
import { setIsLoading } from '../../../store/loading'
import { setProfile } from '../../../store/profile'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate } from "react-router-dom";
import accountService from "../../../services/accountService";
import appHelper from "../../../helper/appHelper";
import ArrowBack from "../../../components/arrowBack";
import auth from "../../../services/auth";
import config from "../../../services/config";
import iconAddress from "../../../assets/images/icon_address.svg"
import iconDate from "../../../assets/images/icon_date.svg"
import iconEmail from "../../../assets/images/icon_email.svg"
import iconUser from "../../../assets/images/icon_user.svg"
import InputBox from '../../../components/input/InputBox'
import moment from "moment";
import PageHeaderTitle from "../../../components/PageHeaderTitle";
import Radio from '@mui/material/Radio';
import React, { useState, useEffect, useRef } from "react";
import { Onfido } from 'onfido-sdk-ui';
import axios from "axios";
import appService from "../../../services/appService";
import iconCloseModal from "../../../assets/images/icon_close_modal.svg";
import './VerifyEmail.css'

const VerifyEmail = () => {
    var meta_data = appHelper.getValueLocalStore(appHelper.keyMetaConfirmBook);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [isSubmit, setIsSubmit] = useState(false);
    const [email, setEmail] = useState('');
    const [emailOtp, setEmailOtp] = useState('');
    const [address, setAddress] = useState(null);
    const settings = useSelector((state) => state.settings.data);
    const zoneUrl = useSelector((state) => state.settings.zone_url);
    const profile = useSelector((state) => state.profile.data)
    const [hasSkippedProfile, setHasSkippedProfile] = useState(false);
    const [emailValid, setEmailValid] = useState(false);
    const refs = [useRef(), useRef(), useRef(), useRef(), useRef(), useRef()];
    const [otp, setOtp] = useState(['', '', '', '', '', '']);
    const [isFilled, setIsFilled] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);
    const [timeRemainingMinutes, setTimeRemainingMinutes] = useState(2);
    const [timeRemainingSeconds, setTimeRemainingSeconds] = useState(59);
    const [showResendLink, setShowResendLink] = useState(false);
    const [isError, setIsError] = useState(false);


    const handleChange = (index, value) => {
        if (value.length > 1) return;
        const newOtp = [...otp];
        newOtp[index] = value;
        setOtp(newOtp);

        if (index < 5 && value.length === 1) {
            refs[index + 1].current.focus();
        }

        const filled = newOtp.every(digit => digit.length === 1);
        setIsFilled(filled);

    };

    const handleKeyDown = (index, event) => {

        if (event.key === 'Backspace' && !otp[index]) {
            if (index > 0) {
                refs[index - 1].current.focus();
            }
        }
    };

    const handleVerify = async () => {
        const enteredOTP = otp.join('');
        if (enteredOTP !== profile.email_otp) {
            setErrorMessage("Wrong OTP, Try Again!");
            setIsError(true)
            dispatch(setDataNotify({
                message: "Wrong OTP, Try Again!",
                severity: 'warning',
            }));
            return;
        }

        try {
            const data = {
                userid: profile.id,
                emailotp: enteredOTP
            }

            const result = await appService.emailOtpValidation(data);
            if (result.code === 200) {
                dispatch(setProfile(result.data));
                if (meta_data) {
                    appHelper.setValueLocalStore(appHelper.keyMetaConfirmBook, { ...meta_data, is_success: true });
                }
                onNavigate();
            } else {
                dispatch(setDataNotify({
                    message: result.error ?? 'Email verification failed, please try again.',
                    severity: 'warning'
                }));
            }

        } catch (error) {
            setErrorMessage("Wrong OTP, Try Again!")
            console.error('Login error:', error.message);
            dispatch(setDataNotify({
                message: `Wrong OTP, Try Again!`,
                severity: 'warning',
            }))
        }
    };

    useEffect(() => {
        if (sessionStorage.getItem("hasSkippedProfile")) {
            setHasSkippedProfile(true);
        }
    }, []);

    useEffect(() => {
        if (!settings) {
            getSettings();
        } else {
            if (profile && profile.id) {
                setEmail(appHelper.checkFakeEmail(profile.email));
                setAddress(profile.address ?? '');
                setIsSubmit(false);
            } else {
                getProfile();
            }
        }
    }, [settings, profile]);

    async function getSettings() {
        try {
            const result = await auth.getSetting();
            if (result && result.data) {
                dispatch(setData(result.data));
            }
        } catch (error) {
            console.log(error);
        }
    }

    async function getProfile() {
        try {
            dispatch(setIsLoading(true));
            await accountService.getProfile().then(async (response) => {
                if (response.data) {
                    dispatch(setProfile(response.data))
                } else {
                    dispatch(setDataNotify({
                        message: 'Account invalid!',
                        severity: 'warning'
                    }));
                    setTimeout(() => {
                        navigate("/login", { replace: true });
                    }, 2500);
                }
            });
        } catch (error) {
            console.log(error);
        }
        dispatch(setIsLoading(false));
    }

    function onValidate() {
        return !appHelper.isEmpty(email) && appHelper.isValidEmail(email)
    }

    function onNavigate() {
        const cProfile = profile
        if (appHelper.profileNeedDLVerify(cProfile)) {
            navigate("/verify-address", { state: { email } });

        }
        else {
            navigate(appHelper.setNavigateUrlMTC(), { state: { cProfile } }, { replace: true });
        }
    }

    function isValidEmail(email) {
        return /\S+@\S+\.\S+/.test(email);
    }

    const handleSkip = () => {
        const cProfile = profile
        setHasSkippedProfile(true);
        sessionStorage.setItem("hasSkippedProfile", "true");
        if (appHelper.profileNeedDLVerify(cProfile)) {
            navigate("/kyc", { state: { cProfile } });

        }
        else {
            navigate(appHelper.setNavigateUrlMTC(), { state: { cProfile } });

        }
    };

    const handleEmailChange = (e) => {
        const inputEmail = e.target.value.toLowerCase();
        setEmail(inputEmail);

        if (!appHelper.isValidEmail(inputEmail)) {
            setEmailValid(false);
        } else {
            setEmailValid(true);
        }
    };

    useEffect(() => {
        const timer = setInterval(() => {
            if (timeRemainingSeconds === 1) {
                if (timeRemainingMinutes === 0) {
                    setShowResendLink(true);
                    clearInterval(timer);
                }
                else {
                    setTimeRemainingMinutes((prevMinutes) => prevMinutes - 1);
                    setTimeRemainingSeconds(59);
                }
            }
            else {
                setTimeRemainingSeconds((prevSeconds) => prevSeconds - 1);
            }
        }, 1000);
        return () => clearInterval(timer);
    }, [timeRemainingMinutes, timeRemainingSeconds]);

    const handleResendOTP = async () => {
        try {
            setTimeRemainingMinutes(2);
            setTimeRemainingSeconds(59);
            setOtp(['', '', '', '', '', '']);
            setShowResendLink(false);
            setIsSubmit(true);
            if (onValidate()) {
                dispatch(setIsLoading(true));

                const data = {
                    email: email,
                }

                const result = await appService.emailValidation(data);
                if (result.code == 200) {
                    dispatch(setProfile(result.data));
                    if (meta_data) {
                        appHelper.setValueLocalStore(appHelper.keyMetaConfirmBook, { ...meta_data, is_success: true });
                    }
                } else {
                    dispatch(setDataNotify({
                        message: result.error ?? 'Email verification failed, please try again.',
                        severity: 'warning'
                    }));
                }
            }
        } catch (error) {
            console.log(error)
        }
        dispatch(setIsLoading(false));
    }

    return <>
        {profile && profile.id ?
            <>
                <div className="air-header-global">
                    {/* <ArrowBack onClickBack={() => { navigate('/edit-profile') }} /> */}
                    <PageHeaderTitle title="Verify your email" />
                </div>
                <div className='HKId-main mt-4'>
                    <div className="row g-0 mt-1 mainDlicense">
                        <div className="col-12 pb-4">
                            <div className="text-center code-text">A 6 digits code has been sent to</div>
                            <div className="text-center exmail-text">
                                <Link to="/edit-profile" className="fw-semibold no-underline ">
                                    {email}
                                </Link>
                            </div>
                        </div>

                        <div className="col-12 pt-5">
                            <div className="code-text">Enter the email validation code</div>
                            <div className="row pt-3 g-0 otp-div">
                                {otp.map((digit, index) => (
                                    <div key={index} className="col-2 px-1 otp-entered">
                                        <input
                                            ref={refs[index]}
                                            type="text"
                                            inputMode="numeric"
                                            className={`form-control input-style ${isError ? 'error-border' : ''}`}
                                            maxLength="1"
                                            value={digit}
                                            onChange={(e) => handleChange(index, e.target.value)}
                                            onKeyDown={(e) => handleKeyDown(index, e)}
                                            required
                                        />
                                    </div>
                                ))}

                                <div>{isError && <p className="text-danger invalid-text pt-1 ps-1">Invalid OTP</p>}</div>
                            </div>
                        </div>

                        <div className="col-12 pt-3">
                            <div className="text-center code-text pt-4">{showResendLink ? (
                                <div className="text-center code-text pt-4">Didn't get the Code?
                                    <br></br>
                                    <Link className="text-center resend-text pt-2 b1-color" onClick={handleResendOTP}> Resend Code</Link>
                                </div>
                            ) : (
                                <div className="text-center resend-text b-3 pt-2">Resend Code in {timeRemainingMinutes === 1 ? `${timeRemainingMinutes} minute` : `${timeRemainingMinutes} minutes` && timeRemainingMinutes === 0 ? `` : `${timeRemainingMinutes} minutes`} {timeRemainingSeconds < 10 ? `0${timeRemainingSeconds}` : timeRemainingSeconds} seconds</div>
                            )}</div>

                        </div>
                    </div>

                    <div className="submitBtn_row edit-xs">
                        <div className="col-12">
                            {
                                meta_data && meta_data.navigate_url && meta_data.navigate_url.includes("mtc") ?
                                    <button
                                        type="submit"
                                        id="button-confirm"
                                        className="btn-style-1"
                                        disabled={!onValidate()}
                                        onClick={() => { handleVerify() }}
                                    >
                                        Continue
                                    </button>
                                    : <>
                                        <button
                                            className="btn-style-1"
                                            style={{ minWidth: '45%' }}
                                            onClick={handleVerify}
                                            disabled={!isFilled}
                                        >
                                            Verify
                                        </button>
                                    </>
                            }
                        </div>
                    </div>

                </div>

            </>
            : ''
        }
    </>
};

export default VerifyEmail;


