import { Backdrop } from "@mui/material";
import { setTimeoutChangeTip } from "../store/settings";
import { useDispatch, useStore } from 'react-redux';
import moment from "moment";
import React, { useEffect, useState } from "react";
import "../assets/style/loading.css"

const tips = [
	"If Carpark gate doesn't open, safely reverse then slowly approach again for rescan.",
	"If experiencing issues at carpark gate, report in app and request carpark office for assistance.",
	"Food and drinks on MTC are banned forever!",
	"Only small pets in bags or containers can become MTC passengers.",
	"Don't forget to take ALL belongings when your trip ends!",
	"Drive like you're carrying a dozen eggs during extreme weather!",
	"Most accidents happen in carparks - be cautious!",
	"Smile for the camera! It makes MTC a safer experience",
];

const messages = [
	"Please wait",
	"Hang tight",
	"Getting things ready",
	"Almost there",
	"Just a moment",
	"Processing",
	"One moment",
	"Hold on",
];

const LoadingAnimation = (props) => {
	const { getState } = useStore();
	const dispatch = useDispatch();
	const [open, setOpen] = useState(false);
	const [classFadeout, setClassFadeout] = useState('');
	const [randomTip, setRandomTip] = useState(tips[0]);
	const [randomMessage, setRandomMessage] = useState(messages[0]);

	useEffect(() => {
		if (open && !props.isOpen) {
			setClassFadeout('fade-out')
		} else {
			setClassFadeout('')
		}
		if (props.isOpen) {
			const timeoutChangeTip = getState().settings.timeout_change_tip;
			if (!timeoutChangeTip || (timeoutChangeTip && moment(timeoutChangeTip) < moment())) {
				dispatch(setTimeoutChangeTip(moment().add(20, 'seconds').toISOString()))
				setRandomTip(getRandomValueFromArray(tips));
				setRandomMessage(getRandomValueFromArray(messages));
			}
		}
		setOpen(props.isOpen)
	}, [props.isOpen])

	function getRandomValueFromArray(arr) {
		const randomIndex = Math.floor(Math.random() * arr.length);
		return arr[randomIndex];
	}

	return (
		<Backdrop
			sx={{ color: "#fff", zIndex: 9999 }}
			open={open}
			className={classFadeout + ' ' + (props?.classLoading ?? '') + (!open ? ' d-none ' : '')}
		>
			<div className={"d-loading " + (props?.classChildBackdrop ?? "")}>
				<div id="mtc-spinner"></div>
				<div className="mtc-loading-text">{randomMessage}<span className="dots"></span></div>
				{open && props?.classChildBackdrop == "full-bg" ? <div className="mtc-tips">{randomTip}</div> : ''}
			</div>
		</Backdrop>
	);
};

export default LoadingAnimation;
