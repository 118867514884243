import { createSlice } from '@reduxjs/toolkit'

export const carparkStations = createSlice({
  name: 'carparkStations',
  initialState: {
    data: [],
    current: null,
    pickup_stations: [],
    drop_off_stations: [],
  },
  reducers: {
    setCarparkStations: (state, action) => {
      state.data = action.payload
    },
    setCurrentCarparkStation: (state, action) => {
      state.current = action.payload
    },
    setPickupStations: (state, action) => {
      state.pickup_stations = action.payload
    },
    setDropOffStations: (state, action) => {
      state.drop_off_stations = action.payload
    },
    clear: (state) => {
      state.data = [];
      state.current = null;
      state.pickup_stations = null;
      state.drop_off_stations = null;
    },
  },
})

export const { setCarparkStations, setCurrentCarparkStation, setPickupStations, setDropOffStations, clear } = carparkStations.actions

export default carparkStations.reducer