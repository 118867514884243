import React, { useEffect, useState } from 'react'
import './Verify.css'
import DocumentImg from '../../assets/images/document.png'
import appService from '../../services/appService';
import { useNavigate } from 'react-router-dom';
import appHelper from '../../helper/appHelper';
import { useDispatch, useSelector } from 'react-redux';
import { setData } from "../../store/settings";
import { setDataNotify } from "../../store/notification";
import { setIsLoading } from "../../store/loading";
import accountService from '../../services/accountService';
import auth from '../../services/auth';
import ArrowBack from '../../components/arrowBack';
import PageHeaderTitle from '../../components/PageHeaderTitle';
import DeclinedImg from '../../assets/images/doc_decline.png'

function KycFinal() {
    var meta_data = appHelper.getValueLocalStore(appHelper.keyMetaConfirmBook);
    const dispatch = useDispatch();
    const [isSubmit, setIsSubmit] = useState(false);
    const navigate = useNavigate()
    const workFlow = localStorage.getItem("Workflow_Run_Id")
    const profile = useSelector((state) => state.profile.data);
    const settings = useSelector((state) => state.settings.data);
    const zoneUrl = useSelector((state) => state.profile.data);
    const [progress, setProgress] = useState(null);
    const applicant = localStorage.getItem("Applicant_id")
    const [reason, setReason] = useState('');

    useEffect(() => {

        if (!settings) {
            getSettings();
        } else {
            setupPage();
        }
    }, [settings]);

    useEffect(() => {
        getProfile();
        const interval = setInterval(() => {
            getProfile();
        }, 40000);
        return () => clearInterval(interval);
    }, []);

    async function getSettings() {
        try {
            const result = await auth.getSetting();
            if (result && result.data) {
                dispatch(setData(result.data));
            }
        } catch (error) {
            console.log(error);
        }
    }

    async function setupPage() {
        dispatch(setIsLoading(true));
        if (profile == null) {
            await getProfile();
        }
        dispatch(setIsLoading(false));
    }
    async function getProfile() {
        await accountService.getProfile().then(async (response) => {
            setProgress(response.data.onfido_document_status)

            if (response.data.onfido_document_status === 'approved') {
                // const checkStatus = async () => {
                //     const res = await appService.getWorkflowRuns(workFlow);
                //     submit(res)
                // };
                // checkStatus();

                if (meta_data) {
                    appHelper.setValueLocalStore(appHelper.keyMetaConfirmBook, { ...meta_data, is_success: true });
                }
                onNavigate()

                // else {
                //     navigate("/mtc")
                // }

            }
            else if (response.data.onfido_document_status === 'declined') {
                // const checkReason = async () => {
                //     const ress = await appService.getWorkflowRuns(workFlow);
                setReason(response.data.onfido_decline_reason)
                // };
                // checkReason();
            }
            else if (response.data.onfido_document_status === '' && response.data.onfido_workflow_id !== '') {
                setProgress('pending')
            }
        })
    }

    const onNavigateScreen = (value) => {
        const zoneSetting = settings.find(setting => setting.key === 'zone_level_url_address');
        const zoneUrl = zoneSetting?.value;
        let user_data = localStorage.getItem('air_car_rental_user');
        const baseUrl = zoneUrl;
        let targetUrl;
        targetUrl = `${baseUrl}/account`;
        window.location.href = targetUrl;
    };

    const downloadMotionDoc = async (doc_id) => {
        const motion = await appService.downloadMotion(doc_id)
        return motion
    }
    const downloadDocImage = async (doc_id) => {
        const image = await appService.downloadDoc(doc_id)
        return image
    }
    const downloadMotionVideo = async (doc_id) => {
        const video = await appService.downloadMotionVid(doc_id)
        return video
    }

    // async function submit(onfidoData) {
    //     let closeLoading = true;
    //     try {
    //         dispatch(setIsLoading(true));
    //         setIsSubmit(true);

    //         const license_id_front = onfidoData?.output?.license_id[0]?.id
    //         const license_id_back = onfidoData?.output?.license_id[1]?.id
    //         const hk_id_front = onfidoData?.output?.hkid_passport_id[0]?.id
    //         const hk_id_back = onfidoData?.output?.hkid_passport_id[1]?.id
    //         const face_id = onfidoData?.output?.selfie_media_id[0]?.id

    //         const license_front_image = license_id_front ? await downloadDocImage(license_id_front) : null
    //         const license_back_image = license_id_back ? await downloadDocImage(license_id_back) : null
    //         const hk_front_image = hk_id_front ? await downloadDocImage(hk_id_front) : null
    //         const hk_back_image = hk_id_back ? await downloadDocImage(hk_id_back) : null
    //         const face_id_image = face_id ? await downloadMotionDoc(face_id) : null
    //         const face_id_video = face_id ? await downloadMotionVideo(face_id) : null


    //         const response = await accountService.verifyIdentificationCard({
    //             front_of_identification_card: onfidoData.output.hkid_passport_issuing_country === "HKG" ? hk_front_image : null,
    //             back_of_identification_card: onfidoData.output.hkid_passport_issuing_country === "HKG" ? hk_back_image : null,
    //             identification_card_issue_date: onfidoData.output.hkid_passport_issue_date,
    //             identification_card_expired_date: onfidoData.output.hkid_passport_expiry_date,
    //             country: onfidoData.output.hkid_passport_issuing_country,
    //             country_short_code: onfidoData.output.hkid_passport_issuing_country,
    //             identification_no: onfidoData.output.hkid_passport_number,
    //             date_of_birth: onfidoData.output.hkid_passport_date_of_birth,
    //             gender: onfidoData.output.hkid_passport_gender,
    //             identification_face_id_document_url: face_id_image,
    //             identification_passport_document_url: onfidoData.output.hkid_passport_issuing_country === "HKG" ? null : hk_front_image,
    //             identification_motion_capture_video_url: face_id_video
    //         });

    //         const result = await accountService.verifyLicense({
    //             front_of_license: license_front_image,
    //             back_of_license: license_back_image,
    //             license_expired_date: onfidoData.output.license_expiry_date,
    //             license_no: onfidoData.output.license_number,
    //             license_issue_date: onfidoData.output.license_issue_date,
    //             onfido_first_name: onfidoData.output.license_first_name,
    //             onfido_last_name: onfidoData.output.license_last_name,
    //             onfido_document_status: onfidoData.status
    //         });

    //         if (result.code == 200) {
    //             await getProfile();
    //             if (meta_data) {
    //                 closeLoading = false;
    //                 appHelper.setValueLocalStore(appHelper.keyMetaConfirmBook, { ...meta_data, is_success: true });
    //             }
    //             onNavigate()

    //             setTimeout(() => {
    //                 onNavigate()
    //             }, 1000);
    //         } else {
    //             dispatch(setDataNotify({ message: result?.error, severity: 'warning' }));
    //         }


    //     } catch (error) {
    //         console.log(error);
    //     }
    //     if (closeLoading) {
    //         dispatch(setIsLoading(false));
    //     }
    // }
    function onNavigate() {
        navigate(appHelper.setNavigateUrlMTC(), { replace: true });
    }

    return (
        <>
            <div className="air-header-global">
                <ArrowBack onClickBack={() => { navigate("/kyc") }} />
                <PageHeaderTitle title="DOCUMENTS VERIFICATION" />
            </div>
            <div className='text-center pt-4'>
                {
                    progress === 'declined' ?
                        <img src={DeclinedImg} className='doc-img'></img>
                        :
                        <img src={DocumentImg} className='doc-img'></img>
                }
            </div>
            <div className='f-20 fw-700 text-center pt-2 t1-color'>
                {
                    progress === 'declined' ?
                        <span>KYC Unsuccessful</span>
                        : <span> We Have Received Your Documents</span>
                }
            </div>
            <div className='d-flex justify-content-center pt-2'>
                {
                    progress === 'declined' ?
                        <div className='f-16 fw-500 lh-18 text-left px-4'>
                            We regret to inform you that your KYC application has been unsuccessful. This could be due to various reasons, including incomplete or incorrect information, or conditions in your surrounding environment that made it difficult to capture clear photos.
                            <br />
                            <div className='fw-700 pt-2'> Thank you for your understanding.</div>
                        </div>
                        :
                        <ul className='f-16 fw-500 pt-2 lh-35 t1-color'>
                            <li>
                                We are reviewing them now
                            </li>
                            <li>
                                We will let you know when we finish
                            </li>
                            <li>
                                This can take  from a few minutes to an hour
                            </li>
                        </ul>
                }
            </div>
            <div className='f-18 fw-700 pt-3 text-center t1-color'>
                KYC STATUS
            </div>
            {
                progress == 'pending' ?
                    <>
                        <div className='text-center pb-2'>
                            {/* <img src={LoaderImg} className='loader-img'></img> */}
                        </div>
                        <div className='f-16 fw-700 text-warning text-center'>Verification in Progress</div>
                    </>
                    : null
            }

            {
                progress == 'approved' ?
                    <div className='f-16 fw-700 t2-color text-center'>Approved</div>
                    : null
            }

            {progress == 'declined' ?
                <>
                    <div className='f-16 fw-700 text-center text-danger'>Declined</div>
                    <div className='f-10 fw-500 text-center text-danger'>{reason}</div>
                </>
                : null
            }

            {
                progress === 'declined' ?
                    ""
                    // <div className='f-14 text-left pt-2 fw-400 lh-16 px-4'>
                    //     NOTE: We recommend reviewing the required documents and ensuring all information is accurate before starting a new application. Additionally, please ensure you have adequate lighting and a stable background to capture clear photos.
                    // </div>
                    :
                    <div className='f-14 fw-600 pt-4 text-center t1-color'>Thank you for your patience</div>
            }
            {/* <div className='text-center mt-4'>
                <button className='common-btn'>
                    <span className='f-24 fw-500'>Learn about our EV</span>
                </button>
            </div> */}

            {progress === 'declined' &&
                <div className='text-center mt-3'>
                    <button type="submit" className='btn-style-1'
                        onClick={() => navigate("/kyc")}
                        style={{ width: "90%" }}
                    >
                        Start New KYC Application
                    </button>

                </div>
            }

            <div className='text-center mt-3'>
                <button type="submit" className='btn-style-1' onClick={() => onNavigateScreen("account")}
                    style={{ width: "90%" }}>
                    View my Account
                </button>
            </div>
        </>
    )
}

export default KycFinal