import { createSlice } from '@reduxjs/toolkit'

export const menu = createSlice({
  name: 'menu',
  initialState: {
    open_right_menu: false,
    open_indicator: false,
  },
  reducers: {
    setOpenRightMenu: (state, action) => {
      state.open_right_menu = action.payload
    },
    setOpenIndicator: (state, action) => {
      state.open_indicator = action.payload
    },
    clear: (state) => {
      state.open_right_menu = false;
      state.open_indicator = false;
    },
  },
})

export const { setOpenRightMenu, setOpenIndicator, clear } = menu.actions

export default menu.reducer