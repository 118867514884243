import { createSlice } from '@reduxjs/toolkit'

export const loading = createSlice({
  name: 'loading',
  initialState: {
    isLoading: false,
  },
  reducers: {
    setIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    clear: (state) => {
      state.isLoading = false;
    },
  },
})

export const { setIsLoading, clear } = loading.actions

export default loading.reducer