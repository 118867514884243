import { createSlice } from '@reduxjs/toolkit'

export const bookingHistoryTab = createSlice({
  name: 'historyTab',
  initialState: {
    tabStatus: '',
    date: null,
    currentPage: 1,
    totalPage: 0,
    bookingId: null
  },
  reducers: {
    setTabStatus: (state, action) => {
      state.tabStatus = action.payload
    },
    setDate: (state, action) => {
      state.date = action.payload
    },
    setBookingId: (state, action) => {
      state.bookingId = action.payload
    },
    setCurrentPage: (state, action) => {
      state.currentPage = action.payload
    },
    setTotalPage: (state, action) => {
      state.totalPage = action.payload
    },
    clear: (state) => {
      state.tabStatus = '';
      state.date = null;
      state.bookingId = null;
      state.totalPage = 0;
      state.currentPage = 1;
    },
  },
})

export const { setTabStatus, setDate, setBookingId, setCurrentPage, setTotalPage, clear } = bookingHistoryTab.actions

export default bookingHistoryTab.reducer