import { createSlice } from '@reduxjs/toolkit'

export const profile = createSlice({
  name: 'profile',
  initialState: {
    data: null,
    kyc_image_detections: null,
  },
  reducers: {
    setProfile: (state, action) => {
      state.data = action.payload;
    },
    setKYCImageDetections: (state, action) => {
      state.kyc_image_detections = action.payload;
    },
    clear: (state) => {
      state.data = null;
      state.kyc_image_detections = null;
    },
  },
})

export const { setProfile, setKYCImageDetections, clear } = profile.actions

export default profile.reducer