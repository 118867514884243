import appHelper from '../helper/appHelper';
import authService from "../services/auth";
import Axios from 'axios'
import config from "./config";

const specialUrls = ["/auth/login", "/auth/logout"];

const axios = Axios.create({
    baseURL: `${config.baseUrl}/${config.version_api}/app`,
    timeout: 15000,
    responseType: "json",
    headers: {
        "Content-Type": "application/json",
        "X-XSRF-COOKIE": "_CSRF",
        "X-XSRF-HEADER": "CSRF"
    }
})

axios.interceptors.request.use((configAxios) => {
    if (configAxios.url.includes('errors')) {
        configAxios.baseURL = configAxios.baseURL.replace('/app', '');
    }
    if (appHelper.getUserInfo('token') && axios.defaults.headers.common['Authorization'] == null) {
        configAxios.headers['Authorization'] = appHelper.getUserInfo('token')
    }
    return configAxios
})

axios.interceptors.response.use(
    async function (response) {
        if (!specialUrls.includes(response?.config?.url) && response.data && [403, 401].includes(response.data.code)) {
            await authService.logout()
        }
        return response
    },
    async function (error) {
        if (!specialUrls.includes(error?.config?.url) && error.response && [403, 401].includes(error.response?.data?.code)) {
            await authService.logout()
        }
        return error.response ?? error
    },
)

export default axios;