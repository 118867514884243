import { createSlice } from '@reduxjs/toolkit'

export const addToHomeScreen = createSlice({
  name: 'addToHomeScreen',
  initialState: {
    isFlagOpen: false,
    userForceClose: false,
  },
  reducers: {
    setIsOpenAddToHomeScreen: (state, action) => {
      state.isFlagOpen = action.payload;
    },
    setUserForceClose: (state, action) => {
      state.userForceClose = action.payload;
    },
    clear: (state) => {
      state.isFlagOpen = false;
      state.userForceClose = false;
    },
  },
})

export const { setIsOpenAddToHomeScreen, setUserForceClose, clear } = addToHomeScreen.actions

export default addToHomeScreen.reducer