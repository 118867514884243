import { AuthProvider } from "./hooks/useAuth";
import { BrowserRouter } from "react-router-dom";
import { Provider } from 'react-redux'
import App from "./App";
import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import store from './store/index'
import "./index.css";
import 'bootstrap/dist/css/bootstrap.min.css';

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
	<Suspense>
		<BrowserRouter>
			<Provider store={store}>
				<AuthProvider>
					<App />
				</AuthProvider>
			</Provider>
		</BrowserRouter>
	</Suspense>
);