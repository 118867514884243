import axios from "./axios";

async function getProfile(params = null) {
	try {
		let response = await axios.get("/users/profile" + (params ? params : ''));
		return response.data;
	} catch (error) {
		return error;
	}
}

async function updateProfile(formData) {
	try {
		axios.defaults.headers.common['Content-Type'] = 'application/x-www-form-urlencoded'
		let response = await axios.put("/users/profile", formData);
		return response.data;
	} catch (error) {
		return error;
	}
}

async function updateForceCloseOnboarding() {
	try {
		let response = await axios.post("/users/update-force-close-onboarding");
		return response.data;
	} catch (error) {
		return error;
	}
}

async function verifyLicense(formData) {
	try {
		let response = await axios.post("/users/verify-license", formData);
		return response.data;
	} catch (error) {
		return error;
	}
}

async function verifyIdentificationCard(formData) {
	try {
		let result = await axios.post("/users/verify-identification-card", formData);
		return result?.data;
	} catch (error) {
		return error;
	}
}

export default {
	getProfile,
	updateProfile,
	verifyLicense,
	verifyIdentificationCard,
	updateForceCloseOnboarding
};
