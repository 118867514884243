const baseUrl = process.env.REACT_APP_BASE_URL ?? 'https://backend.masstransit.hk';
const loginUrl = process.env.REACT_APP_API_KEY ?? 'https://login-backend.masstransit.hk';
const version_api = 'api/v1';
const stripeKey = process.env.REACT_APP_STRIPE_KEY;
const stripeSecret = process.env.REACT_APP_STRIPE_SECRET;
const AWS_S3_ACCESS_KEY_ID = process.env.REACT_APP_AWS_S3_ACCESS_KEY_ID
const AWS_S3_SECRET_ACCESS_KEY = process.env.REACT_APP_AWS_S3_SECRET_ACCESS_KEY
const AWS_S3_BUCKET = process.env.REACT_APP_AWS_S3_BUCKET
const AWS_S3_REGION = process.env.REACT_APP_AWS_S3_REGION
const AWS_S3_BOOKING_VIDEO_FOLDER = "carrental/videos/"
const AWS_S3_DRIVER_LICENSE_FOLDER = "carrental/driver_licenses/"
const AWS_S3_PROFILE_AVATAR_FOLDER = "carrental/profile_avatars/"
const AWS_S3_IDENTIFICATION_CARD_FOLDER = "carrental/identification_cards/"
const AWS_S3_PVT_ACCESS_KEY_ID = "AKIA2UKC73GEFV7H2KHY"
const AWS_S3_PVT_SECRET_ACCESS_KEY = "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"
const AWS_S3_PVT_BUCKET = "aircity-pvt-s3"
const AWS_S3_PVT_REGION = "ap-east-1"
const URL_GOOGLE_MAP_DIR = "https://www.google.com/maps/dir"
const DATE_SUBMIT_FORMAT = 'YYYY-MM-DD';
const DATE_FORMAT = 'DD/MM/YYYY';
const SHORT_DATE_TIME_FORMAT = 'DD MMM HH:mm';
const C_DATE_FORMAT = 'DD MMM YYYY';
const DATE_TIME_FORMAT = DATE_FORMAT + 'HH:mm';
const C_DATE_TIME_FORMAT = C_DATE_FORMAT + ' HH:mm';
const DATE_TIME_FORMAT_MOMENT = DATE_FORMAT + ' HH:mm';
const C_LONG_DATE_TIME_FORMAT = C_DATE_FORMAT + ' hh:mm A';
const MOMENT_LONG_DATE_TIME_FORMAT = C_DATE_FORMAT + ' HH:mm:ss';
const DATE_PICKER_FORMAT = 'dd/MM/yyyy'
const MINUTES_FOR_ADVANCE_CHECK_IN = 15;
const MIN_MINUTES_ALLOW_CHECK_OUT = 5;
const VIDEO_REQUIRED = false;
const IMAGE_TYPE = "image/jpeg";
const FACING_MODE = { exact: "environment" } // rear camera, 
// const FACING_MODE = 'user' // front camera
const IMAGE_LIMIT_SIZE = 4
const IMAGE_FILE_TYPES = ['jpg', 'jpeg', 'png', 'webp']
const MAP_CENTER_DEFAULT = { lat: 22.3193, lng: 114.1694, address: "Mong Kok, Argyle Street, Mong Kok, Yau Tsim Mong District, Kowloon, Hong Kong" }
const ARCGIS_API_KEY = "AAPK2cef4adf67074f969d903020f4aefb6bELWpVnhEz_avhtkGKdEKssEnfI91VNT3_H2WsN3wCdz9f5IuRK-b0SJbdQPaV9LF"
const ARCGIS_WEB_SCENE_ID = "0930dac165d240fcb8ce7ecd297ca077"
const URL_OEMBED_SKETCHFAB = "https://sketchfab.com/oembed?url=https://sketchfab.com/models"
const URL_LUMA_CHECK_VALID_CODE = "https://webapp.engineeringlumalabs.com/api/v3/captures/";
const URL_GOV_API = "https://www.als.ogcio.gov.hk"

export default {
	baseUrl: (baseUrl.endsWith("/") ? baseUrl.slice(0, -1) : baseUrl),
	loginUrl,
	version_api,
	stripeKey,
	stripeSecret,
	AWS_S3_ACCESS_KEY_ID,
	AWS_S3_SECRET_ACCESS_KEY,
	AWS_S3_BUCKET,
	AWS_S3_REGION,
	AWS_S3_PROFILE_AVATAR_FOLDER,
	AWS_S3_DRIVER_LICENSE_FOLDER,
	AWS_S3_IDENTIFICATION_CARD_FOLDER,
	AWS_S3_BOOKING_VIDEO_FOLDER,
	AWS_S3_PVT_ACCESS_KEY_ID,
	AWS_S3_PVT_SECRET_ACCESS_KEY,
	AWS_S3_PVT_BUCKET,
	AWS_S3_PVT_REGION,
	URL_GOOGLE_MAP_DIR,
	DATE_SUBMIT_FORMAT,
	DATE_FORMAT,
	SHORT_DATE_TIME_FORMAT,
	C_DATE_FORMAT,
	C_DATE_TIME_FORMAT,
	C_LONG_DATE_TIME_FORMAT,
	MOMENT_LONG_DATE_TIME_FORMAT,
	DATE_TIME_FORMAT,
	DATE_TIME_FORMAT_MOMENT,
	DATE_PICKER_FORMAT,
	MINUTES_FOR_ADVANCE_CHECK_IN,
	MIN_MINUTES_ALLOW_CHECK_OUT,
	FACING_MODE,
	IMAGE_TYPE,
	IMAGE_LIMIT_SIZE,
	IMAGE_FILE_TYPES,
	VIDEO_REQUIRED,
	MAP_CENTER_DEFAULT,
	ARCGIS_API_KEY,
	ARCGIS_WEB_SCENE_ID,
	URL_OEMBED_SKETCHFAB,
	URL_LUMA_CHECK_VALID_CODE,
	URL_GOV_API
};
