import { Modal as BaseModal } from '@mui/base/Modal';
import { styled, css } from '@mui/system';
import { useSelector } from 'react-redux';
import { useSpring, animated } from '@react-spring/web';
import * as React from 'react';
import iconCloseModal from "./../assets/images/icon_close_modal.svg";
import PropTypes from 'prop-types';

export default function ModalCustom({ open, handleClose, hasIconClose = false, content, classCustom, classContent = "", zIndex, isHidden = false, hideBackdrop = false }) {
    const openRightMenu = useSelector((state) => state.menu.open_right_menu)

    return (
        <div>
            <Modal
                className={"modal-custom " + (classCustom ?? '') + (isHidden ? ' d-none' : '')}
                aria-labelledby="spring-modal-title"
                aria-describedby="spring-modal-description"
                open={open}
                onClose={handleClose}
                closeAfterTransition
                slots={{ backdrop: StyledBackdrop }}
                aria-modal
                hideBackdrop={hideBackdrop}
            >
                <Fade in={open}>
                    <ModalContent sx={style} className={"popup-res " + classContent}>
                        {
                            hasIconClose ?
                                <div className='position-relative'>
                                    <span className='close-modal cursor-pointer' onClick={() => handleClose()}>
                                        <img src={iconCloseModal} alt='Close' />
                                    </span>
                                </div>
                                : ''
                        }
                        <div className='modal-content'>
                            {content}
                        </div>
                    </ModalContent>
                </Fade>
            </Modal>
            {
                open && !isHidden ? <div className="on-open-menu" style={{ zIndex: zIndex ?? (openRightMenu ? 101 : 99) }} /> : ''
            }
        </div>
    );
}

const Backdrop = React.forwardRef((props, ref) => {
    const { open, ...other } = props;
    return <Fade in={open} {...other} />;
});

Backdrop.propTypes = {
    open: PropTypes.bool.isRequired,
};

const Modal = styled(BaseModal)`
  position: fixed;
  z-index: 1300;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledBackdrop = styled(Backdrop)`
  z-index: -1;
  position: fixed;
  inset: 0;
  background-color: rgb(0 0 0 / 0.2);
  -webkit-tap-highlight-color: transparent;
`;

const Fade = React.forwardRef(function Fade(props, ref) {
    const { in: open, children, onEnter, onExited, ...other } = props;
    const style = useSpring({
        from: { opacity: 0 },
        to: { opacity: open ? 1 : 0 },
        onStart: () => {
            if (open && onEnter) {
                onEnter(null, true);
            }
        },
        onRest: () => {
            if (!open && onExited) {
                onExited(null, true);
            }
        },
    });

    return (
        <animated.div ref={ref} style={style} {...other}>
            {children}
        </animated.div>
    );
});

const style = {
    position: 'absolute',
    top: 'auto',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "90%",
};

const ModalContent = styled('div')(
    ({ theme }) => css`
    background: rgba(245, 245, 250, 0.69);
	border: 2px solid rgba(255, 255, 255, 1);
	box-shadow: 0px 4px 39px 0px rgba(0, 0, 0, 0.25);
    -webkit-box-shadow: 0px 4px 39px 0px rgba(0, 0, 0, 0.25);
	-moz-box-shadow: 0px 4px 39px 0px rgba(0, 0, 0, 0.25);
	border-radius: 7px;
    padding: 20px;
    & .modal-title {
      margin: 0;
      line-height: 1.5rem;
      margin-bottom: 8px;
    }
    & .modal-description {
      margin: 0;
      line-height: 1.5rem;
      font-weight: 400;
      margin-bottom: 4px;
    }
  `,
);