import typography from "./Typography";
import shadows from "./Shadows";
import { createTheme } from "@mui/material";

// ##############################

// // // Global Variables
// ##############################

const SidebarWidth = 265;
const TopbarHeight = 70;

const baseTheme = createTheme({
	direction: "ltr",
	palette: {
		primary: {
			main: "#1a97f5",
			light: "#e6f4ff",
		},
		secondary: {
			main: "#777e89",
		},
		background: {
			default: "#f5f5fa",
		},
		success: {
			main: "#39cb7f",
			contrastText: "#ffffff",
		},
		danger: {
			main: "#fc4b6c",
		},
		error: {
			main: "#fc4b6c",
		},
		warning: {
			main: "#fdd43f",
			contrastText: "#ffffff",
		},
		text: {
			secondary: "#777e89",
			danger: "#fc4b6c",
		},
	},
	shape: {
		borderRadius: 5,
	},
	overrides: {
		MuiOutlinedInput: {
			input: {
				"&:-webkit-autofill": {
					"-webkit-box-shadow": "0 0 0 100px #000 inset",
					"-webkit-text-fill-color": "#fff",
				},
			},
		},
	},
	components: {
		MuiOutlinedInput: {
			input: {
				"&:-webkit-autofill": {
					"-webkit-box-shadow": "0 0 0 100px #000 inset",
					"-webkit-text-fill-color": "#fff",
				},
			},
		},
		MuiCssBaseline: {
			styleOverrides: {
				"*": {
					boxSizing: "border-box",
				},
				html: {
					height: "100vh",
					width: "100%",
				},
				body: {
					height: "100vh",
					margin: 0,
					padding: 0,
				},
				"#root": {
					height: "100vh",
				},
			},
		},
		MuiContainer: {
			styleOverrides: {
				root: {
					padding: "0px !important",
				},
			},
		},
		MuiButton: {
			styleOverrides: {
				root: {
					textTransform: "none",
					boxShadow: "none",
					"&:hover": {
						boxShadow: "none",
					},
				},
			},
		},
		MuiListItem: {
			styleOverrides: {
				root: {
					borderRadius: "9px",
				},
			},
		},
		MuiCard: {
			styleOverrides: {
				root: {
					borderRadius: "20px",
					padding: "14px",
					margin: "15px",
				},
			},
		},
		MuiListItemIcon: {
			styleOverrides: {
				root: {
					minWidth: "40px",
				},
			},
		},
		MuiGridItem: {
			styleOverrides: {
				root: {
					paddingTop: "30px",
					paddingLeft: "30px !important",
				},
			},
		},
	},
	mixins: {
		toolbar: {
			color: "#949db2",
			"@media(min-width:1280px)": {
				minHeight: TopbarHeight,
				padding: "0 30px",
			},
			"@media(max-width:1280px)": {
				minHeight: "64px",
			},
		},
	},
	status: {
		danger: "#e53e3e",
	},
	typography,
	shadows,
});

export { TopbarHeight, SidebarWidth, baseTheme };
