import { createSlice } from '@reduxjs/toolkit'

export const loading3DModel = createSlice({
  name: 'loading3DModel',
  initialState: {
    isLoading3D: false,
  },
  reducers: {
    setIsLoading3D: (state, action) => {
      state.isLoading3D = action.payload;
    },
    clear: (state) => {
      state.isLoading3D = false;
    },
  },
})

export const { setIsLoading3D, clear } = loading3DModel.actions

export default loading3DModel.reducer