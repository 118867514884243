import React, { useEffect } from 'react'
import { useState } from 'react'
import config from '../../services/config'
import Select from 'react-select'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";

export default function InputBox({
  label,
  placeholder,
  onChangeFunction,
  beforeComponent = null,
  afterComponent = null,
  type = 'text',
  className = '',
  classNameInputCustom = '',
  feedbackInvalid,
  classContent = '',
  classLabel = 'air-label-custom',
  classDiv = 'air-input-custom',
  typeInput = 'input',
  selectOptions = [],
  params = null,
  isCheckSpecialCharacters = false,
  ...props
}) {
  const [classFocus, setClassFocus] = useState('');

  function renderContentInput() {
    if (typeInput == 'textarea') {
      return (
        <textarea
          type={type}
          className={"textarea-notes " + className}
          placeholder={placeholder}
          onChange={onChangeFunction}
          {...props}
        />
      )
    } else if (typeInput == 'select') {
      return (
        <Select
          name="role"
          classNamePrefix="select"
          isClearable={params.clearable ?? false}
          isSearchable={params.searchable ?? true}
          options={selectOptions}
          className={className}
          placeholder={placeholder}
          onChange={onChangeFunction}
          {...props}
        />
      )
    } else if (typeInput == 'muiDateTimePicker') {
      return (
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <MobileDatePicker
            label={label}
            placeholder={placeholder}
            value={props.defaultValue}
            onChange={onChangeFunction}
            closeOnSelect={params?.closeOnSelect ?? true}
            format={params?.format ?? config.DATE_FORMAT}
            shouldDisableDate={props.disableDate}
            {...props}
          />
        </LocalizationProvider>
      )
    } else if (typeInput == 'dateTimePicker') {
      return (
        <DatePicker
          selected={props?.defaultValue ? new Date(props.defaultValue) : null}
          className={className}
          placeholderText={placeholder}
          dateFormat={params?.format ?? config.DATE_PICKER_FORMAT}
          locale={params?.locale ?? 'en'}
          onChange={onChangeFunction}
          maxDate={params?.maxDate ?? null}
          minDate={params?.minDate ?? null}
          {...props}
        />
      )
    } else {
      return (
        <input
          type={type}
          className={className}
          placeholder={placeholder}
          onInput={onChangeFunction}
          onKeyPress={(e) => isCheckSpecialCharacters ? restrict(e) : ''}
          {...props}
        />
      )
    }
  }

  function restrict(event) {
    const regex = new RegExp("^[0-9a-zA-Z]+$");
    const key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
    if (!regex.test(key)) {
      event.preventDefault();
      return false;
    }
  }

  return (
    <div className={'d-content-input ' + classContent}>
      {label && <label className={classLabel}>{label} {props.required ? <span className='text-danger'>*</span> : ''}</label>}
      <div
        className={`${classDiv} ${classFocus} ${feedbackInvalid ? 'invalid' : ''
          } ${classNameInputCustom}`}
        onFocus={() => setClassFocus('focus')}
        onBlur={() => setClassFocus('')}
      >
        {beforeComponent ? beforeComponent : <></>}
        {renderContentInput()}
        {afterComponent ? afterComponent : <></>}
      </div>
      {feedbackInvalid && <div className="invalid-message mb-1">{feedbackInvalid}</div>}
    </div>
  )
}
