import { setData } from "../../../store/settings";
import { setDataNotify } from '../../../store/notification'
import { setIsLoading } from '../../../store/loading'
import { setProfile } from '../../../store/profile'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from "react-router-dom";
import appService from "../../../services/appService";
import accountService from "../../../services/accountService";
import appHelper from "../../../helper/appHelper";
import ArrowBack from "../../../components/arrowBack";
import auth from "../../../services/auth";
import PageHeaderTitle from "../../../components/PageHeaderTitle";
import React, { useState, useEffect } from "react";
import './VerifyEmail.css'
function VerifyAddress() {

    var meta_data = appHelper.getValueLocalStore(appHelper.keyMetaConfirmBook);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [isSubmit, setIsSubmit] = useState(false);
    const [email, setEmail] = useState('');
    const [address, setAddress] = useState("");
    const settings = useSelector((state) => state.settings.data);
    const profile = useSelector((state) => state.profile.data)
    const [hasSkippedProfile, setHasSkippedProfile] = useState(false);
    const [isFilled, setIsFilled] = useState(false);

    async function handleSubmit() {
        try {
            setIsSubmit(true);
            dispatch(setIsLoading(true));

            const data = { address: address };
            const resultFromParser = await appService.addressParser(data);

            if (resultFromParser && Array.isArray(resultFromParser)) {
                const result = {
                    street_address_1: "",
                    street_address_2: "",
                    city: "",
                    state: "",
                    postal_code: "",
                    country_code: "",
                    additional_info: "",
                    raw_input: ""
                };

                resultFromParser.forEach(item => {
                    switch (item.component) {
                        case "house":
                            result.street_address_1 += item.value + " ";
                            break;
                        case "road":
                            result.street_address_2 += item.value;
                            break;
                        case "city":
                            result.city = item.value;
                            break;
                        case "postcode":
                            result.postal_code = item.value;
                            break;
                        case "country":
                            result.country_code = item.value;
                            break;
                        // default:
                        //     result.additional_info = item.value;
                        //     break;

                    }
                });


                result.street_address_1 = result.street_address_1.trim();
                const response = await appService.saveAddress(result);
                if (meta_data) {
                    appHelper.setValueLocalStore(appHelper.keyMetaConfirmBook, { ...meta_data, is_success: true });
                }
                onNavigate();
            } else {
                dispatch(setDataNotify({
                    message: resultFromParser.error ?? 'Invalid Address, please try again.',
                    severity: 'warning'
                }));
            }
        } catch (error) {
            console.error("Error submitting address:", error);
            dispatch(setDataNotify({
                message: 'An error occurred while processing your request.',
                severity: 'error'
            }));
        } finally {
            setIsSubmit(false);
            dispatch(setIsLoading(false));
        }
    }

    useEffect(() => {
        if (sessionStorage.getItem("hasSkippedProfile")) {
            setHasSkippedProfile(true);
        }
    }, []);

    useEffect(() => {
        if (!settings) {
            getSettings();
        } else {
            if (profile && profile.id) {
                setAddress(((profile.street_address_1 ?? '') + ' ' + (profile.street_address_2 ?? '') + ' ' + (profile.city ?? '') + ' ' + (profile.state ?? '') + ' ' + (profile.country_code ?? '') + ' ' + (profile.postal_code ?? '')) ?? '');
                if (profile.street_address_1 || profile.street_address_2 || profile.city || profile.state || profile.country_code || profile.postal_code) {
                    setIsSubmit(true);
                    setIsFilled(true);
                }
                setIsSubmit(false);
            } else {
                getProfile();
            }
        }
    }, [settings, profile]);

    async function getSettings() {
        try {
            const result = await auth.getSetting();
            if (result && result.data) {
                dispatch(setData(result.data));
            }
        } catch (error) {
            console.log(error);
        }
    }

    async function getProfile() {
        try {
            dispatch(setIsLoading(true));
            await accountService.getProfile().then(async (response) => {
                if (response.data) {
                    dispatch(setProfile(response.data))
                } else {
                    dispatch(setDataNotify({
                        message: 'Account invalid!',
                        severity: 'warning'
                    }));
                    setTimeout(() => {
                        navigate("/login", { replace: true });
                    }, 2500);
                }
            });
        } catch (error) {
            console.log(error);
        }
        dispatch(setIsLoading(false));
    }


    function onNavigate() {
        const cProfile = profile
        if (appHelper.profileNeedDLVerify(cProfile)) {
            navigate("/kyc", { state: { email } }
            );

        }
        else {
            navigate(appHelper.setNavigateUrlMTC(), { state: { cProfile } }, { replace: true });
        }
    }

    const handleChange = (e) => {
        const value = e.target.value;
        setAddress(value);
        setIsFilled(value.trim() !== "");
    };

    return <>
        {profile && profile.id ?
            <>
                <div className="air-header-global">
                    <ArrowBack onClickBack={() => { navigate('/edit-profile') }} />
                    <PageHeaderTitle title="Your Address" />
                </div>

                <div className='HKId-main mt-4'>
                    <div className="col-12 pb-4 text-center">
                        <textarea
                            placeholder='Your Address'
                            value={address}
                            rows="4"
                            onChange={handleChange}
                            className={`my-address-input input-text ${!address ? 'input-error-box' : ''}`}
                        />
                        <p className={`position-absolute float-address ${!address ? 'input-error-text' : ''}`}>Your Address</p>
                        {!address ? <div className='field-required text-start'>Invalid Address</div> : ''}
                    </div>

                    <div className="submitBtn_row edit-xs">
                        <div className="col-12">
                            <button
                                className="btn-style-1"
                                style={{ minWidth: '45%' }}
                                onClick={handleSubmit}
                                disabled={!isFilled || isSubmit}
                            >
                                Submit
                            </button>
                        </div>
                    </div>
                </div>
            </>
            : ''
        }
    </>

}

export default VerifyAddress