import React, { useState, useRef, useEffect } from 'react';
import { Container, Box, Button, Typography } from "@mui/material";
import OtpInput from 'react-otp-input';
import EastIcon from '@mui/icons-material/East';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import appService, { login, loginUser, loginVerify, sendMessage, verifyOTP } from '../services/appService';
import './OTP.css';
import MtcLogo from './mtc_logo';
import appTheme from "../consts/appTheme";
import { useAuth } from '../hooks/useAuth';
import authService from "../services/auth";
import { useLocalStorage } from '../hooks/useLocalStorage';
import appHelper from '../helper/appHelper';
import { useDispatch, useSelector, useStore } from 'react-redux';
import { setDataNotify } from '../store/notification';
import { setOngoingBooking } from '../store/bookings';
import accountService from '../services/accountService';
import { setProfile } from '../store/profile';

const OTPForm = ({ onConfirmOTP, setOtpStep, validated, phoneNumber }) => {
    const [otp, setOtp] = useState(['', '', '', '', '', '']);
    const [timeRemaining, setTimeRemaining] = useState(30);
    const [showResendLink, setShowResendLink] = useState(false);
    const [isFilled, setIsFilled] = useState(false);
    const [isClicked, setIsClicked] = useState(false);

    const refs = [useRef(), useRef(), useRef(), useRef(), useRef(), useRef()];
    const location = useLocation();
    const navigate = useNavigate();
    const { login, user } = useAuth();
    const [userData, setUserData] = useState('')
    const loginResponseFromLocation = location.state && location.state.loginResponse ? location.state.loginResponse : null;
    const selectedCountryCode = location.state?.selectedCountryCode || '';
    const [loginResponse, setLoginResponse] = useState(loginResponseFromLocation);
    const user_data = useLocalStorage(appHelper.AuthLocalStore, userData)
    const dispatch = useDispatch();
    const [errorMessage, setErrorMessage] = useState(null);
    const isSendingMessage = useRef(false);
    const { getState } = useStore();
    const profile = useSelector((state) => state.profile.data);
    const [chromeOtp, setChromeOtp] = useState(['']);
    const userAgent = navigator.userAgent.toLowerCase();
    const isSafari = userAgent.includes('safari') && !userAgent.includes('chrome') && !userAgent.includes('crios') && !userAgent.includes('edg');
    const handleChange = (index, value) => {
        if (value.length > 1) return;
        const newOtp = [...otp];
        newOtp[index] = value;
        setOtp(newOtp);

        if (index < 5 && value.length === 1) {
            refs[index + 1].current.focus();
        }

        const filled = newOtp.every(digit => digit.length === 1);
        setIsFilled(filled);
        if (filled) {
            handleVerify(newOtp);
        }
    };
    const handleOnChange = (event) => {
        const { value } = event.target;
        if (/^\d*$/.test(value) && value.length <= 6) {
            setChromeOtp(value);
            setIsFilled(value.length === 6);
            if (value.length === 6) {
                handleOnVerify(value);
            }
        }
    };

    const handleKeyDown = (index, event) => {
        if (event.key === 'Backspace' && !otp[index]) {
            if (index > 0) {
                refs[index - 1].current.focus();
            }
        }
    };

    async function getProfile() {
        try {
            if (!profile && appHelper.getUserInfo("token")) {
                const res = await accountService.getProfile();
                if (res?.data && res?.code == 200) {
                    const data = res.data;
                    // dispatch(setProfile(data));
                    return res.data
                } else {
                    navigate("/login")
                }
            } else {
                navigate("/login")
            }
        } catch (error) {

        }
    }
    const handleOnVerify = async (otpString) => {
        try {
            const response = await verifyOTP({
                userid: loginResponse.id,
                phonenumber: loginResponse.phonenumber,
                loginotp: otpString
            });

            if (response.code === 200) {
                const data = await onFirebaseLogin(loginResponse.phonenumber);

                if (data && data.data) {
                    setUserData(data?.data);
                }
                if (appHelper.getUserInfo("token")) {
                    const resOngoingBooking = await appService.getOngoingBooking();
                    if (resOngoingBooking?.data && resOngoingBooking?.data?.id) {
                        dispatch(setOngoingBooking(resOngoingBooking.data));
                        navigate("/activity/" + resOngoingBooking.data.id);
                    }
                    else {
                        const cProfile = await getProfile();

                        if (!cProfile.email || !appHelper.checkFakeEmail(cProfile.email)) {
                            navigate("/edit-profile");
                        } else if (appHelper.profileNeedDLVerify(cProfile)) {
                            navigate("/kyc", { state: { cProfile } });
                        }
                        else {
                            navigate(appHelper.setNavigateUrlMTC());
                        }

                    }
                }
            }

            else {
                console.error("response", response);

                setErrorMessage("Wrong OTP, Try Again!");

            }
        }
        catch (error) {
            // setErrorMessage("Wrong OTP, Try Again!");

            // console.error('Login error:', error);
            // dispatch(setDataNotify({
            //     message: `Wrong OTP, Try Again!`,
            //     severity: 'warning',
            // }));
        }
    };

    const handleVerify = async (otpArray) => {
        const enteredOTP = otpArray.join('');

        try {
            const response = await verifyOTP({
                userid: loginResponse.id,
                phonenumber: loginResponse.phonenumber,
                loginotp: enteredOTP
            });

            if (response.code === 200) {
                const data = await onFirebaseLogin(loginResponse.phonenumber);
                if (data && data.data) {
                    setUserData(data?.data)
                    // onAuthSuccess(data?.data);

                }

                if (appHelper.getUserInfo("token")) {
                    const resOngoingBooking = await appService.getOngoingBooking();
                    if (resOngoingBooking?.data && resOngoingBooking?.data?.id) {
                        dispatch(setOngoingBooking(resOngoingBooking.data))
                        navigate("/activity/" + resOngoingBooking.data.id, { replace: true });
                    }
                    else {
                        const cProfile = await getProfile();

                        if (!cProfile.email || !appHelper.checkFakeEmail(cProfile.email)) {
                            navigate("/edit-profile");
                        } else if (appHelper.profileNeedDLVerify(cProfile)) {
                            navigate("/kyc", { state: { cProfile } });
                        }
                        else {
                            navigate(appHelper.setNavigateUrlMTC());
                        }

                    }
                }


            }

            else {
                setErrorMessage("Wrong OTP, Try Again!")
            }
        } catch (error) {
            // setErrorMessage("Wrong OTP, Try Again!")
            // console.error('Login error:', error.message);
            // dispatch(setDataNotify({
            //     message: `Wrong OTP, Try Again!`,
            //     severity: 'warning',
            // }))
        }
    };
    async function onFirebaseLogin(user) {
        await authService.loginAuthenticate(user).then((response) => {
            if (response.data) {
                onAuthSuccess(response.data);
            } else {
                console.log("Authenticate error!")
            }
        });

    }


    function onAuthSuccess(data) {
        login(data);
    }

    useEffect(() => {
        const timer = setInterval(() => {
            setTimeRemaining((prevTime) => (prevTime === 0 ? 30 : prevTime - 1));
            if (timeRemaining === 0) {
                setShowResendLink(true);
                clearInterval(timer);
            }
        }, 1000);

        return () => clearInterval(timer);
    }, [timeRemaining]);
    const phoneData = JSON.parse(sessionStorage.getItem('phoneData'));
    const [phone, countryCode] = phoneData;

    const handleResendOTP = async () => {
        try {
            setTimeRemaining(30);
            setShowResendLink(false);

            setOtp(['', '', '', '', '', '']);
            setChromeOtp([''])
            if (!phoneData || phoneData.length < 2) {
                dispatch(setDataNotify({
                    message: `Failed to resend OTP. Please try again.`,
                    severity: 'warning',
                }))
                setErrorMessage("Failed to resend OTP. Please try again.");
                setShowResendLink(true);
                return;
            }

            const phoneNumber = phoneData[0];
            const countryCode = phoneData[1][0];
            const result = await loginUser({ phonenumber: `+${countryCode}${phoneNumber}` });

            if (result && result.data && result.data.loginotp) {
                // if (!isSendingMessage.current) {
                //     isSendingMessage.current = true;

                //     const environment = process.env.REACT_APP_ENV;
                //     const domain = environment === 'staging' ? '@staging.masstransit.hk' : '@masstransit.hk';
                //     await sendMessage({
                //         body: `Aircity login OTP is ${result.data.loginotp}\n${domain} #${result.data.loginotp}`,
                //         toNumber: phoneNumber,
                //         countryCode: selectedCountryCode,
                //         type: "SMS",
                //     });
                //     isSendingMessage.current = false;
                // }
            } else {
                setErrorMessage("Failed to resend OTP. Please try again.");
                dispatch(setDataNotify({
                    message: `Failed to resend OTP. Please try again.`,
                    severity: 'warning',
                }))
                setShowResendLink(true);

                console.log("Login failed!! Check Credentials");
            }
        } catch (error) {
            console.log(error, "error")
            setErrorMessage("Failed to resend OTP. Please try again..");
            setShowResendLink(true);

            console.error('Resend OTP error:', error.message);
        }
    };

    useEffect(() => {
        const abortController = new AbortController();
        // const userAgent = navigator.userAgent.toLowerCase();
        // const isSafari = userAgent.includes('safari') 
        const fetchOTPSafari = async () => {
            if ('OTPCredential' in window) {
                try {
                    const content = await navigator.credentials.get({
                        otp: { transport: ['sms'] },
                        signal: abortController.signal
                    });
                    if (content && content.code) {
                        const otpMatch = content.code.match(/\b(\d{6})\b/);
                        if (otpMatch && otpMatch[1]) {
                            const otpArray = otpMatch[1].split('');
                            setOtp(otpArray);
                            setIsFilled(true);
                            handleVerify(otpArray);
                        }
                    }
                } catch (err) {
                    if (err.name !== 'AbortError') {
                        console.error('Error with Web OTP API:', err);
                    }
                }
            }
        };
        const fetchOTP = async () => {
            if ('OTPCredential' in window) {
                try {
                    const content = await navigator.credentials.get({
                        otp: { transport: ['sms'] },
                        signal: abortController.signal
                    })
                    if (content && content.code) {
                        const otpMatch = content.code.match(/\b(\d{6})\b/);
                        if (otpMatch && otpMatch[1]) {
                            const otpCode = otpMatch[1];
                            setChromeOtp(otpCode);
                            setIsFilled(true);
                            handleOnVerify(otpMatch[1]);
                        }
                    }
                } catch (err) {
                    if (err.name !== 'AbortError') {
                        console.error('Error with Web OTP API:', err);
                    }
                }
            }
        };
        if (!isSafari) {
            fetchOTP()

        } else {
            fetchOTPSafari()


        }

        return () => {
            abortController.abort();
            setOtp(['', '', '', '', '', '']);
            setChromeOtp(['']);
            setIsFilled(false);
        };
    }, []);
    return (
        <Container sx={{ ...appTheme.screen, p: 2, height: '100vh' }} className="card-login" style={{ width: "400px !important" }}>
            <Box sx={{ ...appTheme.layoutOutside, backgroundColor: "#F5F5FA" }}>
                <MtcLogo sx={{ pt: '35px' }} />
                <Box
                    sx={{
                        flexDirection: "column",
                        pt: 3,
                        margin: '0 auto',
                        textAlign: "initial"
                    }}
                    className="card-login d-center"
                >

                    {/* <div className='available_upper'> */}
                    <div className='text-left'>
                        <div className="row">
                            <div className="col">
                                <h2 className="heading-2">Enter OTP</h2>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <p className="paragraph">Enter the OTP sent to <Link to="/login" className="fw-semibold no-underline">+{countryCode}{phone}</Link></p>
                                {/* <p>OTP : {loginResponse.loginotp}</p> */}
                            </div>
                        </div>
                        <form autoComplete="on">
                            {isSafari ? (
                                <div className="row pt-3 g-0 otp-div">
                                    {otp.map((digit, index) => (
                                        <div key={index} className="col-2 px-1 otp-entered">
                                            <input
                                                ref={refs[index]}
                                                type="text"
                                                inputMode="numeric"
                                                className="form-control input-style"
                                                maxLength="1"
                                                value={digit}
                                                onChange={(e) => handleChange(index, e.target.value)}
                                                onKeyDown={(e) => handleKeyDown(index, e)}
                                                autoComplete="one-time-code"
                                                required
                                            />
                                        </div>
                                    ))}


                                </div>
                            )
                                :
                                <div className=" px-1 otp-entered">

                                    <div className="text-center position-relative">
                                        {/* <input
                                            // ref={refs[index]}
                                            type="text"
                                            inputMode="numeric"
                                            className="form-control input-style"
                                            maxLength="6"
                                            value={chromeOtp}
                                            onChange={(e) => handleOnChange(e)}
                                            autoComplete="one-time-code"
                                            required
                                        /> */}

                                        <input
                                            type='text'
                                            inputMode="numeric"
                                            maxLength="6"
                                            value={chromeOtp}
                                            onChange={(e) => handleOnChange(e)}
                                            autoComplete="one-time-code"
                                            required
                                            className="form-control my-details-input input-text input-style"
                                        />
                                        <p className={`position-absolute float-name`}>OTP</p>
                                    </div>
                                </div>
                            }
                        </form>
                        {errorMessage && (
                            <Box className="pt-2">
                                <span className="text-danger">{errorMessage}</span>
                            </Box>
                        )}
                        <div className="row">
                            <div className="col text-center pt-3 time-otp">
                                {showResendLink ? (
                                    <p className="time-para fw-semibold">Didn't receive OTP?
                                        <Link className="no-underline" onClick={handleResendOTP}> Resend OTP</Link>
                                    </p>
                                ) : (
                                    <p className="time-para">You can request <span className="time-para-otp">OTP</span> again in <span className="time-para-time">{timeRemaining}</span> secs</p>
                                )}
                            </div>
                        </div>
                    </div>

                    <div className=" Verify-otp submitBtn_row ">
                        <div className=" text-center">
                            <button type="button" className="btn-style-1" onClick={isSafari ? handleVerify : handleOnVerify} disabled={!isFilled}>
                                Verify
                            </button>
                        </div>


                    </div>
                    {/* </div> */}
                    {/* </div> */}
                    {/* </div > */}
                    {/* </Box>
                    </Box> */}

                </Box>
            </Box>
        </Container >
    );
}

export default OTPForm;