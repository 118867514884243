import { getAuth, signOut } from "firebase/auth";
import { initializeApp } from "firebase/app";
import appHelper from "../helper/appHelper";
import axios from "./axios";
import firebaseConfig from "../firebase_credential.json"
const BASE_URL = process.env.REACT_APP_BASE_URL;
const appFirebase = initializeApp(firebaseConfig);
let user = localStorage.getItem("air_car_rental_user");
const token = user?.token
function setAuthorizationToken(token) {
	if (token) {
		axios.defaults.headers.common["Authorization"] = token;
	} else {
		delete axios.defaults.headers.common["Authorization"];
	}
}

async function loginAuthenticate(phone) {
	let body = {
		phone_number: phone,
		// providerData: user.providerData && user.providerData.length ? user.providerData[0] : null,
		// firebase_token: user.accessToken,
		device_info: window?.navigator?.userAgent
	};
	try {
		let response = await axios.post(`${BASE_URL}/api/v1/app/auth/phonenumberlogin`, body);
		return response.data;
	} catch (error) {
		return error;
	}
}

async function logout() {
	try {
		await axios.post("/auth/logout");
		const authFirebase = getAuth(appFirebase);
		await signOut(authFirebase);
	} catch (error) {
		console.log(error);
	}
	appHelper.clearValueLocalStore(appHelper.AuthLocalStore);
	window.location = '/login';
}

async function getSetting() {
	try {
		const response = await axios.get("/settings");
		return response.data;
	} catch (error) {
		console.log(error);
		return error;
	}
}


export default {
	setAuthorizationToken,
	// firebaseAuthenticate,
	loginAuthenticate,
	logout,
	getSetting
};
