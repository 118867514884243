import { createSlice } from '@reduxjs/toolkit'

export const verifyUnlock = createSlice({
    name: 'verifyUnlock',
    initialState: {
        detect_live_face: false,
        verify_success: false
    },
    reducers: {
        setDetectLiveFace: (state, action) => {
            state.detect_live_face = action.payload;
        },
        setVerifySuccess: (state, action) => {
            state.verify_success = action.payload;
        },
        clear: (state) => {
            state.detect_live_face = false;
            state.verify_success = false;
        },
    },
})

export const { setDetectLiveFace, setVerifySuccess, clear } = verifyUnlock.actions

export default verifyUnlock.reducer