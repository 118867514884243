import { createSlice } from '@reduxjs/toolkit'

export const currentLocation = createSlice({
  name: 'currentLocation',
  initialState: {
    data: null,
    forceGetLocation: 0,
    warned: 0,
  },
  reducers: {
    setData: (state, action) => {
      state.data = action.payload
    },
    setForceGetLocation: (state, action) => {
      state.forceGetLocation = action.payload
    },
    setWarned: (state, action) => {
      state.warned = action.payload
    },
    clear: (state) => {
      state.data = false;
      state.warned = 0;
    },
  },
})

export const { setData, setForceGetLocation, setWarned, clear } = currentLocation.actions

export default currentLocation.reducer