import axios from "./axios";
import config from "./config";
const userDataString = localStorage.getItem('air_car_rental_user');
const userData = userDataString ? JSON.parse(userDataString) : null;
const token = userData?.token;
const userId = userData?.user_id
const API_KEY = process.env.REACT_APP_API_KEY;
const BASE_URL = process.env.REACT_APP_BASE_URL;
const VERSION = config.version_api
async function getServices(params) {
	try {
		let response = await axios.get("/services/dropdown-list?" + (params ?? ''));
		return response.data;
	} catch (error) {
		return error;
	}
}

async function getCars(params) {
	try {
		let response = await axios.get("/cars?" + (params ?? ''));
		return response.data;
	} catch (error) {
		return error;
	}
}

async function getCarDetail(id, params) {
	try {
		let response = await axios.get("/cars/" + id + (params ?? ''));
		return response?.data;
	} catch (error) {
		return error;
	}
}

async function cloneCars() {
	try {
		let response = await axios.get("/cars/clone-cars");
		return response?.data;
	} catch (error) {
		return error;
	}
}

async function booking(body) {
	try {
		let response = await axios.post("/bookings", body);
		return response?.data;
	} catch (error) {
		return error;
	}
}

async function getBookings(params) {
	try {
		let response = await axios.get("/bookings?" + (params ?? ''));
		return response?.data;
	} catch (error) {
		return error;
	}
}

async function getBookingDetail(booking_id) {
	try {
		let response = await axios.get("/bookings/" + booking_id);
		return response?.data;
	} catch (error) {
		return error;
	}
}

async function updateBookingReturnStation(booking_id, body) {
	try {
		let response = await axios.post("bookings/" + booking_id + "/return-station", body);
		return response?.data;
	} catch (error) {
		return error;
	}
}

async function getBookingSummary(booking_id, params) {
	try {
		let response = await axios.get("/bookings/" + booking_id + "/summary" + (params ? "?" + params : ''));
		return response?.data;
	} catch (error) {
		return error;
	}
}

async function getOngoingBooking() {
	try {
		let response = await axios.get("/bookings/ongoing-booking");
		return response?.data;
	} catch (error) {
		return error;
	}
}

async function checkInBooking(booking_id, body) {
	try {
		let response = await axios.post('bookings/' + booking_id + '/check-in', body);
		return response?.data;
	} catch (error) {
		return error;
	}
}

async function checkoutBooking(booking_id, body) {
	try {
		let response = await axios.post('bookings/' + booking_id + '/checkout', body);
		return response?.data;
	} catch (error) {
		return error;
	}
}

async function cancelBooking(booking_id, body) {
	try {
		let response = await axios.post('bookings/' + booking_id + '/cancel', body);
		return response?.data;
	} catch (error) {
		return error;
	}
}

async function resolveTransactionFailed(booking_id, body) {
	try {
		let response = await axios.post('bookings/' + booking_id + '/resolve-transaction-failed', body);
		return response?.data;
	} catch (error) {
		return error;
	}
}

async function ratingBooking(booking_id, body) {
	try {
		let response = await axios.post("/bookings/" + booking_id + "/rating", body);
		return response?.data;
	} catch (error) {
		return error;
	}
}

async function checkAvailableCharge(booking_id) {
	try {
		let response = await axios.post("bookings/" + booking_id + "/available-chargers");
		return response?.data;
	} catch (error) {
		return error;
	}
}

async function sendCommandCar(booking_id, body) {
	try {
		let response = await axios.post("/bookings/" + booking_id + "/command-car-lock", body);
		let result = response.data;
		return result;
	} catch (error) {
		return error;
	}
}

async function commandHazardLights(booking_id, body) {
	try {
		let response = await axios.post("/bookings/" + booking_id + "/command-hazard-light", body);
		let result = response;
		return result;
	} catch (error) {
		return error;
	}
}

async function getCarparkStations(params) {
	try {
		let response = await axios.get('carpark-stations/dropdown-list?' + (params ?? ''));
		return response?.data;
	} catch (error) {
		return error;
	}
}

async function getAreas(params) {
	try {
		let response = await axios.get('areas/dropdown-list?' + (params ?? ''));
		return response?.data;
	} catch (error) {
		return error;
	}
}

async function getDistricts(params) {
	try {
		let response = await axios.get('districts/dropdown-list?' + (params ?? ''));
		return response?.data;
	} catch (error) {
		return error;
	}
}

async function getViewPointLevels(params) {
	try {
		let response = await axios.get('view-levels/dropdown-list?' + (params ?? ''));
		return response?.data;
	} catch (error) {
		return error;
	}
}

async function getAvailableCar(params) {
	try {
		let response = await axios.get('view-levels/available-cars?' + (params ?? ''));
		return response?.data;
	} catch (error) {
		return error;
	}
}

async function getCarTypes() {
	try {
		let response = await axios.get('car-types/dropdown-list');
		return response?.data;
	} catch (error) {
		return error;
	}
}


async function getPaymentMethods() {
	try {
		let response = await axios.get("/payment-methods");
		return response?.data;
	} catch (error) {
		return error;
	}
}

async function setDefaultPaymentMethod(paymentMethodId) {
	try {
		let response = await axios.post("/payment-methods/" + paymentMethodId + "/default");
		return response?.data;
	} catch (error) {
		return error;
	}
}

async function requestNewSetupIntent() {
	try {
		let response = await axios.post("/payment-methods/new-setup-intent");
		return response?.data;
	} catch (error) {
		return error;
	}
}

async function confirmSetupIntent(setupIntent) {
	try {
		let response = await axios.post("/payment-methods/confirm-setup-intent", { setupIntent: setupIntent });
		return response?.data;
	} catch (error) {
		return error;
	}
}

async function getServiceErrorMessage(error, service, defaultMessage = "Something went wrong, please try again.") {
	let message = error.message ?? defaultMessage
	try {
		if (error.code) {
			const response = await axios.get("/errors?code=" + error.code + "&service=" + service);
			if (response.status == 200 && response.data && response.data.data && response.data.data.message) {
				message = response.data.data.message;
			}
		}
		return message;
	} catch (error) {
		console.log(error);
	}
	return message;
}

async function getCarPark() {
	try {
		let response = await axios.get('https://carparking-node.treasuretop.zone/v2/portal/carpark/get');
		return response?.data;
	} catch (error) {
		return error;
	}
}

async function getCancelReasons() {
	try {
		let response = await axios.get('/cancel-reasons/dropdown-list');
		return response?.data;
	} catch (error) {
		return error;
	}
}

export const loginUser = async (phone) => {
	try {
		const { data } = await axios.post(
			`${API_KEY}/user/login`,
			phone
		);
		return data;
	} catch (err) {
		throw Error(err);
	}
};

export const verifyOTP = async (formData) => {
	try {
		const { data } = await axios.post(
			`${API_KEY}/user/verifyotp`,
			formData
		);
		return data;
	} catch (err) {
		throw Error(err);
	}
};

export const service = async (formData) => {
	try {
		const { data } = await axios.post(
			`${API_KEY}/user/select/service`,
			formData
		);
		return data;
	} catch (err) {
		throw Error(err);
	}
};


export const sendMessage = async (params) => {
	try {
		const { body, toNumber, countryCode, type } = params;
		const url = `https://carparking-node.treasuretop.zone/v2/twilio/message/send?body=${encodeURIComponent(body)}&toNumber=${toNumber}&countryCode=${countryCode}&type=${type}`;
		const { data } = await axios.get(url);
		return data;
	} catch (err) {
		throw Error(err);
	}
};


async function rating(data) {
	try {
		let response = await axios.post("/ratings/createRating", data,
			{
				headers: {
					Authorization: token
				}
			}
		);
		return response?.data;
	} catch (error) {
		return error;
	}
}
async function ratingCriteria() {
	try {
		let response = await axios.get(`${BASE_URL}/api/v1/portal/ratingcriteria/getCriteria`,
			{
				headers: {
					Authorization: token
				}
			}
		);
		return response?.data?.data?.rows;
	} catch (error) {
		return error;
	}
}


async function getCarsNearest(query) {
	try {
		let response = await axios.get('cars/get-cars-nearest?' + (query ?? ''));
		return response?.data;
	} catch (error) {
		return error;
	}
}

async function profileUpdate(data) {
	try {
		let response = await axios.post(`${BASE_URL}/api/v1/portal/users/${userId}/updateprofile`, data);
		return response?.data;
	} catch (error) {
		return error;
	}
}

const fetchWeatherData = async () => {
	try {
		const response = await fetch("https://data.weather.gov.hk/weatherAPI/opendata/weather.php?dataType=flw&lang=en");
		const json = await response.json();
		return json;
	} catch (error) {
		console.error("Error fetching the weather data: ", error);
		throw error;
	}
};

async function requestCar(data) {
	try {
		let response = await axios.post('carrequest/create', data,
			{
				headers: {
					Authorization: token
				}
			}
		);
		return response.data;
	} catch (error) {
		return error;
	}
}

async function requestAvailableCar(params) {
	try {
		let response = await axios.get("cars/request-available-car?" + (params ?? ''),
			{
				headers: {
					Authorization: token
				}
			}
		);
		return response.data;
	} catch (error) {
		return error;
	}
}

async function estimatedTime(params) {
	try {
		let response = await axios.get("cars/estimatedtime-request-available-car?" + (params ?? ''),
			{
				headers: {
					Authorization: token
				}
			}
		);
		return response.data;

	} catch (error) {
		return error;
	}
}

export const loginVerify = async (phone) => {
	try {
		const response = await axios.post("auth/phonenumberlogin", phone);
		return response.data;
	} catch (err) {
		throw Error(err);
	}
};

export const createApplicant = async (data) => {
	const url = BASE_URL + "/" + VERSION + "/app" + "/onfido/applicants/create";
	try {
		const response = await fetch(url, {
			method: "POST",
			body: data,
			headers: {
				'Content-Type': 'application/json',
			}
		});
		const json = await response.json();
		return json;
	} catch (err) {
		throw Error(err);
	}
}

export const generateSDKToken = async (data) => {
	const url = BASE_URL + "/" + VERSION + "/app" + "/onfido/sdk_token";
	try {
		const response = await fetch(url, {
			method: "POST",
			body: data,
			headers: {
				'Content-Type': 'application/json',
			}
		});
		const json = await response.json();
		return json.data;
	} catch (err) {
		throw Error(err);
	}
}

export const getDocumentById = async () => {
	const url = BASE_URL + "/" + VERSION + "/app" + `/onfido/document/${id}`;
	try {
		const response = await fetch(url, {
			method: "GET",
			headers: {
				'Content-Type': 'application/json',
			}
		});
		const json = await response.json();
		return json;
	} catch (err) {
		throw Error(err);
	}
}

export const downloadDocument = async () => {
	const url = BASE_URL + "/" + VERSION + "/app" + `/onfido/document/${id}/download`;
	try {
		const response = await fetch(url, {
			method: "GET",
			headers: {
				'Content-Type': 'application/json',
			}
		});
		const json = await response.json();
		return json;
	} catch (err) {
		throw Error(err);
	}
}

export const createWorkflowRun = async (data) => {
	const url = BASE_URL + "/" + VERSION + "/app" + "/onfido/workflow_runs/create";
	try {
		const response = await fetch(url, {
			method: "POST",
			body: data,
			headers: {
				'Content-Type': 'application/json',
			}
		});
		const json = await response.json();
		return json.data;
	} catch (err) {
		throw Error(err);
	}
}

export const getWorkflowRuns = async (id) => {
	const url = BASE_URL + "/" + VERSION + "/app" + `/onfido/workflow_runs/${id}`;
	try {
		const response = await fetch(url, {
			method: "GET",
			headers: {
				'Content-Type': 'application/json',
			}
		});
		const json = await response.json();
		return json.data;
	} catch (err) {
		throw Error(err);
	}
}

export const getDeclineReason = async (id) => {
	const url = BASE_URL + "/" + VERSION + "/app" + `/onfido/applicant/document/${id}`;
	try {
		const response = await fetch(url, {
			method: "GET",
			headers: {
				'Content-Type': 'application/json',
			}
		});
		const json = await response.json();
		return json.data;
	} catch (err) {
		throw Error(err);
	}
}

export const documentList = async (id) => {
	const url = BASE_URL + "/" + VERSION + "/app" + `/onfido/applicant/document/${id}`;
	try {
		const response = await fetch(url, {
			method: "GET",
			headers: {
				'Content-Type': 'application/json',
			}
		});
		const json = await response.json();
		return json.data;
	} catch (err) {
		throw Error(err);
	}
}


async function saveWorkflowRun(data) {

	try {
		let response = await axios.put('users/onfido-workflow-update', data, {
			headers: {
				Authorization: token,
				'Content-Type': 'application/json'
			}
		})
		return response.data;
	} catch (error) {
		return error;
	}
}

export const downloadMotion = async (doc_id) => {
	const url = BASE_URL + "/" + VERSION + "/app" + `/onfido/motioncaptureframe/${doc_id}/download`;
	try {
		const response = await fetch(url, {
			method: "GET",
			headers: {
				'Content-Type': 'application/json',
			}
		});
		const json = await response.json();

		return json.data;
	} catch (err) {
		throw Error(err);
	}
}

export const downloadDoc = async (doc_id) => {
	const url = BASE_URL + "/" + VERSION + "/app" + `/onfido/document/${doc_id}/download`;
	try {
		const response = await fetch(url, {
			method: "GET",
			headers: {
				'Content-Type': 'application/json',
			}
		});
		const json = await response.json();
		return json.data;
	} catch (err) {
		throw Error(err);
	}
}

export const downloadMotionVid = async (doc_id) => {
	const url = BASE_URL + "/" + VERSION + "/app" + `/onfido/motioncapture/${doc_id}/download`;
	try {
		const response = await fetch(url, {
			method: "GET",
			headers: {
				'Content-Type': 'application/json',
			}
		});
		const json = await response.json();

		return json.data;
	} catch (err) {
		throw Error(err);
	}
}


async function emailValidation(email) {
	try {
		const response = await axios.put("users/profile/updateemail", email);
		return response.data;
	} catch (err) {
		console.log(err)
		throw Error(err);
	}
}

async function emailOtpValidation(otp) {
	try {
		const response = await axios.post("users/verifyemailotp", otp);
		return response.data;
	} catch (err) {
		throw Error(err);
	}
}

async function saveAddress(data) {
	try {
		const response = await axios.put("users/profile/updateaddress", data);
		return response.data;
	} catch (err) {
		throw Error(err);
	}
}


async function addressParser(data) {
	try {
		const response = await axios.post(`${BASE_URL}/api/webhook/get_address_libpostal`, data);

		return response.data.data;
	} catch (err) {
		throw Error(err);
	}
}


export default {
	saveAddress,
	addressParser,
	emailValidation,
	emailOtpValidation,
	getCarPark,
	getServices,
	getCars,
	getBookings,
	getBookingDetail,
	getBookingSummary,
	getOngoingBooking,
	updateBookingReturnStation,
	resolveTransactionFailed,
	booking,
	getCarDetail,
	checkInBooking,
	checkoutBooking,
	cancelBooking,
	ratingBooking,
	cloneCars,
	getDistricts,
	getAreas,
	getCarparkStations,
	getPaymentMethods,
	requestNewSetupIntent,
	setDefaultPaymentMethod,
	confirmSetupIntent,
	getServiceErrorMessage,
	getCarTypes,
	sendCommandCar,
	commandHazardLights,
	getViewPointLevels,
	getAvailableCar,
	getCancelReasons,
	rating,
	ratingCriteria,
	getCarsNearest,
	checkAvailableCharge,
	fetchWeatherData,
	profileUpdate,
	requestCar,
	requestAvailableCar,
	estimatedTime,
	createApplicant,
	generateSDKToken,
	getDocumentById,
	downloadDocument,
	createWorkflowRun,
	getWorkflowRuns,
	documentList,
	saveWorkflowRun,
	downloadMotion,
	downloadDoc,
	downloadMotionVid,
	getDeclineReason
};


