import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit'
import bookings from './bookings'
import verifyUnlock from './verifyUnlock'
import historyTab from './bookingHistoryTab'
import carHistoryTab from './carHistoryTab'
import currentLocation from './currentLocation'
import carparkStations from './carparkStations'
import notification from './notification'
import loading from './loading'
import loading3DModel from './loading3DModel'
import profile from './profile'
import obj3DModel from './obj3DModel'
import settings from './settings'
import addToHomeScreen from "./addToHomeScreen"
import viewPointLevels from './viewPointLevels'
import services from './services'
import menu from './menu'

export default configureStore({
  reducer: {
    historyTab: historyTab,
    carHistoryTab: carHistoryTab,
    currentLocation: currentLocation,
    notification: notification,
    loading: loading,
    loading3DModel: loading3DModel,
    profile: profile,
    obj3DModel: obj3DModel,
    settings: settings,
    carparkStations: carparkStations,
    addToHomeScreen: addToHomeScreen,
    bookings: bookings,
    viewPointLevels: viewPointLevels,
    services: services,
    menu: menu,
    verifyUnlock: verifyUnlock
  },
  middleware: getDefaultMiddleware({
    serializableCheck: false
  }),
})