import React from "react";
import { Box } from "@mui/material";

const MtcLogo = (props) => {
    let sx = {
        margin: "0 auto",
        textAlign: "center",
        ...props.sx
    }
    return (
        <Box sx={sx} className="box-logo">
            <img
                className={"logo-container " + (props.classLogo ?? '')}
                src={require("../assets/images/mtc_logo.png")}
                alt="MTC"
            />
        </Box>
    );
};

export default MtcLogo;
