import React, { useEffect, useState } from 'react'
import '../booking/Rating.css'
import appService, { downloadMotionVid } from '../../services/appService';
import { useNavigate } from 'react-router-dom';
import appHelper from '../../helper/appHelper';
import loader from "../../assets/images/hour_glass.png"
import { Padding } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { setData } from "../../store/settings";
import { setDataNotify } from "../../store/notification";
import { setIsLoading } from "../../store/loading";
import { setProfile } from "../../store/profile";
import accountService from '../../services/accountService';
import moment from "moment";
import auth from '../../services/auth';
import { Hourglass } from 'react-loader-spinner';

function DocStatus() {
    var meta_data = appHelper.getValueLocalStore(appHelper.keyMetaConfirmBook);
    const dispatch = useDispatch();
    const [isSubmit, setIsSubmit] = useState(false);
    const navigate = useNavigate()
    const workFlow = localStorage.getItem("Workflow_Run_Id")
    const profile = useSelector((state) => state.profile.data);
    const settings = useSelector((state) => state.settings.data);
    const zoneUrl = useSelector((state) => state.profile.data);

    useEffect(() => {

        if (!settings) {
            getSettings();
        } else {
            setupPage();
        }
    }, [settings]);

    useEffect(() => {
        const pollWorkflowStatus = async (workFlow, interval = 8000, maxAttempts = 45) => {
            let attempts = 0;
            const checkStatus = async () => {
                const response = await appService.getWorkflowRuns(workFlow);
                if (response.status !== "processing" || attempts >= maxAttempts) {
                    if (response.status === "approved") {

                        submit(response)
                    }
                    return response;
                } else {
                    attempts++;
                    setTimeout(checkStatus, interval);
                }
            };
            checkStatus();
        };

        pollWorkflowStatus(workFlow);
    }, [workFlow])

    async function getSettings() {
        try {
            const result = await auth.getSetting();
            if (result && result.data) {
                dispatch(setData(result.data));
            }
        } catch (error) {
            console.log(error);
        }
    }

    async function setupPage() {
        dispatch(setIsLoading(true));
        if (profile == null) {
            await getProfile();
        }
        dispatch(setIsLoading(false));
    }
    async function getProfile() {
        await accountService.getProfile().then(async (response) => {
            if (response.data) {
                const data = response.data;
                dispatch(setProfile(data));
            } else {
                setTimeout(() => {
                    navigate("/login");
                }, 2500);
            }
        });
    }

    const downloadMotionDoc = async (doc_id) => {
        const motion = await appService.downloadMotion(doc_id)
        return motion
    }
    const downloadDocImage = async (doc_id) => {
        const image = await appService.downloadDoc(doc_id)
        return image
    }
    const downloadMotionVideo = async (doc_id) => {
        const video = await appService.downloadMotionVid(doc_id)
        return video
    }
    async function submit(onfidoData) {
        let closeLoading = true;
        try {
            dispatch(setIsLoading(true));
            setIsSubmit(true);

            const license_id_front = onfidoData?.output?.license_id[0]?.id
            const license_id_back = onfidoData?.output?.license_id[1]?.id
            const hk_id_front = onfidoData?.output?.hkid_passport_id[0]?.id
            const hk_id_back = onfidoData?.output?.hkid_passport_id[1]?.id
            const face_id = onfidoData?.output?.selfie_media_id[0]?.id

            const license_front_image = license_id_front ? await downloadDocImage(license_id_front) : null
            const license_back_image = license_id_back ? await downloadDocImage(license_id_back) : null
            const hk_front_image = hk_id_front ? await downloadDocImage(hk_id_front) : null
            const hk_back_image = hk_id_back ? await downloadDocImage(hk_id_back) : null
            const face_id_image = face_id ? await downloadMotionDoc(face_id) : null
            const face_id_video = face_id ? await downloadMotionVideo(face_id) : null


            const response = await accountService.verifyIdentificationCard({
                front_of_identification_card: onfidoData.output.document_issuing_country === "HKG" ? hk_front_image : null,
                back_of_identification_card: onfidoData.output.document_issuing_country === "HKG" ? hk_back_image : null,
                identification_card_issue_date: onfidoData.output.hkid_passport_issue_date,
                identification_card_expired_date: onfidoData.output.hkid_passport_expiry_date,
                country: onfidoData.output.document_issuing_country,
                country_short_code: onfidoData.output.document_issuing_country,
                identification_no: onfidoData.output.hkid_passport_number,
                date_of_birth: onfidoData.output.hkid_passport_date_of_birth,
                gender: onfidoData.output.hkid_passport_gender,
                identification_face_id_document_url: face_id_image,
                identification_passport_document_url: onfidoData.output.document_issuing_country === "HKG" ? null : hk_front_image,
                identification_motion_capture_video_url: face_id_video
            });

            const result = await accountService.verifyLicense({
                front_of_license: license_front_image,
                back_of_license: license_back_image,
                license_expired_date: onfidoData.output.license_expiry_date,
                license_no: onfidoData.output.license_number,
                license_issue_date: onfidoData.output.license_issue_date,
                onfido_first_name: onfidoData.output.license_first_name,
                onfido_last_name: onfidoData.output.license_last_name,
                onfido_document_status: onfidoData.status
            });

            if (result.code == 200) {
                await getProfile();
                if (meta_data) {
                    closeLoading = false;
                    appHelper.setValueLocalStore(appHelper.keyMetaConfirmBook, { ...meta_data, is_success: true });
                }
                setTimeout(() => {
                    onNavigate()
                }, 1000);
            } else {
                dispatch(setDataNotify({ message: result?.error, severity: 'warning' }));
            }


        } catch (error) {
            console.log(error);
        }
        if (closeLoading) {
            dispatch(setIsLoading(false));
        }
    }


    function onNavigate() {
        navigate(appHelper.setNavigateUrlMTC(), { replace: true });
    }
    return (
        <>
            <div className="container_custom h-100">
                <div className="row g-0 d-none">
                    <div className="col-12 top-heading text-uppercase">
                        {/* <PageHeaderTitle title="" cClass='d-center w-100' /> */}
                    </div>
                </div>
                <div className='box-content-global loading-box text-center MuiBox-root css-0' >
                    <div className='main-box' style={{ justifyContent: "center" }}>
                        <div className="row loading-div g-0 p-3">

                            <div className="col-12 d-flex justify-content-center">
                                <Hourglass
                                    visible={true}
                                    height="80"
                                    width="80"
                                    ariaLabel="hourglass-loading"
                                    wrapperStyle={{}}
                                    wrapperClass=""
                                    colors={['#A9D9FF', '#7eb2ff']}
                                />
                                {/* <img src={loader} className="img-fluid checked-image" alt="Check" /> */}
                            </div>
                            <div className="col-12 middle-heading mt-4">
                                Please Wait...
                            </div>
                            <div className="col-12">
                                Your KYC documents are under verification.
                                <br />
                                It will take few minutes.
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </>
    )
}

export default DocStatus