import { baseTheme } from "./assets/global/Theme-variable";
import { BottomMenuProvider } from "./contexts/BottomMainMenuContext";
import { ThemeProvider } from "@mui/material";
import { useRoutes } from "react-router-dom";
import { useSelector } from 'react-redux';
import auth from "./services/auth";
import Loading from "./components/LoadingAnimation.js";
import React from "react";
import UserRoute from "./routes/Routes";

const App = () => {
	const isLoading = useSelector((state) => state.loading.isLoading)
	const isLoading3D = useSelector((state) => state.loading3DModel.isLoading3D)
	let user_data = localStorage.getItem("air_car_rental_user");
	const token = user_data?.token

	if (user_data) {
		auth.setAuthorizationToken(token);
	}
	let routing = useRoutes(UserRoute.getRoutes());
	const theme = baseTheme;

	window.onorientationchange = (event) => {
		if (screen && screen.orientation?.type?.includes("landscape")) {
			alert("Please rotate your phone screen to portrait mode.")
		}
	};

	return <ThemeProvider theme={theme}>
		<Loading isOpen={isLoading3D ? isLoading3D : isLoading} classChildBackdrop={isLoading3D ? "full-bg" : ""} />
		<BottomMenuProvider>
			{routing}
		</BottomMenuProvider>
	</ThemeProvider>;
};

export default App;
